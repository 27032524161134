// node_modules/@aws-amplify/ui-react-native/src/primitives
// for all the primitives you can override
//
// Sign in contain path: node_modules/@aws-amplify/ui-react-native/src/Authenticator/common/DefaultContainer/styles.ts
import { ColorMode, View, Image, useTheme } from "@aws-amplify/ui-react";
import React from "react";

export const CustomAmplifyComponents = {
  Header() {
    const [colorMode, setColorMode] = React.useState<ColorMode>("system");

    const { tokens } = useTheme();

    const logoSrc =
      colorMode === "dark"
        ? "https://pikcheck-static-files.s3.amazonaws.com/logo-images/logo_wide_v1_dm.png"
        : "https://pikcheck-static-files.s3.amazonaws.com/logo-images/logo_wide_v1_lm.png";
    return (
      <View
        textAlign="center"
        padding={tokens.space.large}
        paddingTop={"100px"}
      >
        <Image alt="Amplify logo" src={logoSrc} />
      </View>
    );
  },
};
