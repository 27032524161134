import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { resetPassword } from "../../services/apiHelper/users";

export const ResetPassword = ({
  userToUpdate,
  handleCloseDialog,
}: {
  userToUpdate: string | null;
  handleCloseDialog: () => void;
}) => {
  const [password, setPassword] = useState<string>("Welcome2024");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const passwordValidation = (password: string): string[] => {
    const errors: string[] = [];

    if (!/[A-Z]/.test(password)) {
      errors.push("Password should contain at least one uppercase character.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password should contain at least one lowercase character.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password should contain at least one number.");
    }
    if (password.length < 8) {
      errors.push("Password should be at least 8 characters long.");
    }

    return errors;
  };

  const countdown = useRef<number>(5);
  const [timerDisplay, setTimerDisplay] = useState<number>(countdown.current);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (isSuccess && countdown.current > 0) {
      timer = setInterval(() => {
        countdown.current -= 1;
        setTimerDisplay(countdown.current);
        if (countdown.current === 0) {
          if (timer) clearInterval(timer);
          handleCloseDialog();
        }
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isSuccess, handleCloseDialog]);

  useEffect(() => {
    if (!userToUpdate) {
      return;
    }
    setIsSubmitting(false);
    setIsSuccess(false);
    setPassword("Welcome2024");
    setPasswordErrors([]);
    countdown.current = 5; // Reset the countdown
  }, [userToUpdate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errors = passwordValidation(event.target.value);
    setPasswordErrors(errors);
    setPassword(event.target.value);
  };

  return (
    <Dialog
      open={!!userToUpdate}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClick={(event) => event.stopPropagation()} // Stops the click event from propagating
    >
      <DialogTitle id="alert-dialog-title">{"Reset User Password"}</DialogTitle>
      <DialogContent>
        {isSuccess ? (
          <>
            <Typography
              // align="center"
              variant="h6"
              style={{ marginTop: 10, minWidth: 400 }}
            >
              Password reset successfully!
            </Typography>
            <Typography
              align="center"
              variant="body1"
              style={{ marginTop: 20 }}
            >
              Closing in {timerDisplay} seconds...
            </Typography>
          </>
        ) : (
          <TextField
            label="Password"
            variant="outlined"
            value={password}
            onChange={handleChange}
            fullWidth
            required
            error={passwordErrors.length > 0}
            helperText={
              passwordErrors.length > 0 ? (
                <ul style={{ margin: 0, paddingLeft: 20, fontSize: "16px" }}>
                  {passwordErrors.map((error, index) => (
                    <li key={index} style={{ color: "red" }}>
                      {error}
                    </li>
                  ))}
                </ul>
              ) : null
            }
            style={{ marginTop: 10, minWidth: 400 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            handleCloseDialog();
            event.stopPropagation();
          }}
          variant="contained"
          color="primary"
        >
          {isSuccess ? "Close" : "Cancel"}
        </Button>
        <Button
          onClick={async (event) => {
            setIsSubmitting(true);
            if (!userToUpdate) {
              return;
            }
            try {
              const res = await resetPassword(userToUpdate, password);

              if (res) {
                setIsSuccess(true);
                countdown.current = 4; // Reset the countdown when password reset is successful
              }
            } catch (e: any) {
              console.log(e);
              setPasswordErrors([
                "Unable to reset password. Try again soon or contact support.",
              ]);
            } finally {
              setIsSubmitting(false);
            }

            event.stopPropagation();
          }}
          variant="contained"
          color="primary"
          autoFocus
          style={{
            display: isSuccess ? "none" : "block",
            textAlign: "center",
          }}
        >
          {isSubmitting ? "Resetting..." : "Reset"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
