import React, { useEffect } from "react";

import NavBar from "../components/navbar/NavBar";
import SideBar from "../components/SideBar";
import { Box, CssBaseline } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { getSelf } from "../services/apiHelper/users";
import { UserDocument } from "../constants/types";
import { USER_TYPES } from "../config";

const Layout = () => {
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = React.useState<UserDocument | null>(null);
  const [userNotAuthorized, setUserNotAuthorized] =
    React.useState<boolean>(false);

  const navigate = useNavigate();

  const drawerWidth = 240;

  useEffect(() => {
    getSelf().then((res) => {
      setUserData(res.userData);

      const userNotAuthorized_ = res.userData.userType !== USER_TYPES.LENDER;

      if (userNotAuthorized_) {
        navigate("/nu");
      }
      setUserNotAuthorized(userNotAuthorized_);
    });
  }, [navigate]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "background.default",
        overscrollBehavior: "none",
      }}
    >
      <CssBaseline />
      {!userNotAuthorized && (
        <>
          <SideBar open={open} drawerWidth={drawerWidth} />
          <NavBar
            username={
              (userData?.firstName || "") + " " + (userData?.lastName || "")
            }
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            drawerWidth={drawerWidth}
          />
        </>
      )}

      <Outlet context={{ open, drawerWidth }} />
    </Box>
  );
};

export default Layout;
