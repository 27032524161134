import ApiService from "../api";
import { BASEPATHS } from "../../config";
import store from "../../ts/store";

export interface UserType_API_CALL {
  _id?: string;
  firstName: string;
  lastName: string;
  password?: string;
  email: string;
  borrowingEntity: string;
  loanProID: string;
  userType: number;
}

export interface UpdateUser_API_CALL {
  _id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  notes?: string;
  borrowingEntity?: string;
  loanProID?: string;
}

export const createUser = async (user: UserType_API_CALL) => {
  const apiService = new ApiService();

  try {
    const userData = await apiService.post<any>({
      path: "/createUser",
      dataType: BASEPATHS.user,
      data: {
        firstName: user.firstName,
        lastName: user.lastName,
        password: user.password,
        email: user.email,
        userType: user.userType,
        loanProID: user.loanProID,
        borrowingEntity: user.borrowingEntity,
      },
    });

    return userData;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const resendInvitation = async (
  userId: string,
  username: string,
  email: string
) => {
  const apiService = new ApiService();

  try {
    const userData = await apiService.post<any>({
      path: "/resendInvitation",
      dataType: BASEPATHS.user,
      data: {
        userId,
        username,
        email,
      },
    });

    return userData;
  } catch (error) {
    console.error("Error resending user invitation:", error);
    throw error;
  }
};

export const updateLCUser = async (user: UpdateUser_API_CALL) => {
  const apiService = new ApiService();

  try {
    const userData = await apiService.post<any>({
      path: "/updateLCUser",
      dataType: BASEPATHS.user,
      data: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        notes: user.notes,
        borrowingEntity: user.borrowingEntity,
        loanProID: user.loanProID,
      },
    });

    return userData;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const deleteUser = async (userId: string) => {
  const apiService = new ApiService();

  try {
    const userData = await apiService.post<any>({
      path: "/deleteUser",
      dataType: BASEPATHS.user,
      data: {
        userId,
      },
    });

    return userData;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const resetPassword = async (userId: string, password: string) => {
  const apiService = new ApiService();

  try {
    const userData = await apiService.post<any>({
      path: "/resetUserPassword",
      dataType: BASEPATHS.user,
      data: {
        userId: userId,
        newPassword: password,
      },
    });

    return userData;
  } catch (error) {
    console.error("Error ressetting user password:", error);
    throw error;
  }
};

export const getUsers = async () => {
  const apiService = new ApiService();

  try {
    const usersData = await apiService.get<any>({
      path: "/getUsers",
      dataType: BASEPATHS.user,
      data: {},
    });

    store.dispatch({
      type: "UPDATE_LC_USERS",
      usersData: usersData.users,
    });
    return usersData;
  } catch (error) {
    console.error("Error getting users:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const getSelf = async () => {
  const apiService = new ApiService();

  try {
    const userData = await apiService.get<any>({
      path: "/getSelf",
      dataType: BASEPATHS.user,
      data: {},
    });
    store.dispatch({
      type: "UPDATE_USER_DATA",
      userData: userData.userData,
    });

    return userData;
  } catch (error) {
    console.error("Error getSelf:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
