import React, { Fragment, useMemo, useState } from "react";

import {
  Card,
  CardContent,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InspectionDocument,
  InspectionImageDocument,
  RoomDocument,
} from "../../constants/types";
import { CustomImageV2 } from "../common/Images/CustomImageV2";

interface RoomImagesGridProps {
  imageList: InspectionImageDocument[];
  propertyInspectionsData: InspectionDocument[];
  roomData: RoomDocument;
}

const DisplayDate = ({ date }: { date?: Date }) => {
  const formattedDate = date ? new Date(date).toLocaleDateString() : "";
  return <Typography sx={{ textAlign: "center" }}>{formattedDate}</Typography>;
};

const RenderPropertyImages = ({
  imageList,
}: {
  imageList: InspectionImageDocument[];
}) => {
  return (
    <ImageList sx={{ height: 250 }} cols={1} variant="masonry">
      {imageList.map((item) => (
        <ImageListItem key={item._id}>
          <CustomImageV2
            image={item.image.imagePath}
            imageStyle={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            shouldPlay={true}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

const RenderCardColumn = ({
  inspectionId,
  images,
  propertyInspectionsData,
}: {
  inspectionId: string;
  images: InspectionImageDocument[];
  propertyInspectionsData: InspectionDocument[];
}) => (
  <Grid key={inspectionId} item xs={4}>
    <RenderPropertyImages imageList={images} />
    <DisplayDate
      date={
        propertyInspectionsData.find(
          (inspection) => inspection._id === inspectionId
        )?.date
      }
    />
  </Grid>
);

const RoomImagesGrid: React.FC<RoomImagesGridProps> = ({
  imageList,
  roomData,
  propertyInspectionsData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const groupedImages = useMemo(() => {
    const groups: Record<string, InspectionImageDocument[]> = {};
    imageList.forEach((image) => {
      if (!groups[image.inspection]) {
        groups[image.inspection] = [];
      }
      groups[image.inspection].push(image);
    });
    return groups;
  }, [imageList]);

  const firstThreeInspections = Object.entries(groupedImages)
    .slice(0, 3)
    .map(([inspectionId, images]) => ({ inspectionId, images }));

  return (
    <Card
      sx={{
        backgroundColor: "background.paper",
        "&:hover": {
          backgroundColor: "backgroundMoreColors.paper",
          cursor: "pointer",
        },
      }}
      onClick={() => {
        const porpertyId =
          propertyInspectionsData.length > 0
            ? propertyInspectionsData[0].property
            : "";

        const fullPath = location.pathname; // we are using full path instead of base path
        // because we also want to keep the path params
        const newPath = `${fullPath}/insp?roomId=${roomData._id}`;

        navigate(newPath);
      }}
    >
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2.75}>
          <Grid item xs={12}>
            <Typography variant="h6">{roomData.name}</Typography>
          </Grid>

          {firstThreeInspections.length === 0 ? (
            <Grid item xs={12}>
              <Typography>No images Uploaded</Typography>
            </Grid>
          ) : (
            firstThreeInspections.map(({ inspectionId, images }) => (
              // add a key
              <Fragment key={inspectionId}>
                <RenderCardColumn
                  inspectionId={inspectionId}
                  images={images}
                  propertyInspectionsData={propertyInspectionsData}
                />
              </Fragment>
            ))
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RoomImagesGrid;
