import React, { Fragment, useMemo, useState } from "react";

import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import GenericHeader from "./GenericHeader";
import Fuse from "fuse.js";
import { ExpandLess, ExpandMore, MoreVert } from "@mui/icons-material";
import {
  DashboardHeadCell,
  HeaderButtonDataProps,
} from "../../../constants/types";

interface TableHeaderProps<DataRowType> {
  order: "asc" | "desc";
  orderBy: string;
  onRequestSort: (property: keyof DataRowType) => void;
  headCells: DashboardHeadCell<DataRowType>[];
  moreOptionsMenuExists?: boolean;
}

type Order = "asc" | "desc";

const TableHeader = <DataRowType extends { _id: string }>({
  order,
  orderBy,
  onRequestSort,
  headCells,
  moreOptionsMenuExists,
}: TableHeaderProps<DataRowType>) => {
  const createSortHandler = (property: keyof DataRowType) => () => {
    onRequestSort(property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: DashboardHeadCell<DataRowType>) =>
          headCell.customHeadCell ? (
            <React.Fragment
              key={
                headCell.columnKey
                  ? headCell.columnKey
                  : (headCell.id as string)
              }
            >
              {headCell.customHeadCell(headCell.id)}
            </React.Fragment>
          ) : (
            <TableCell
              key={
                headCell.columnKey
                  ? headCell.columnKey
                  : (headCell.id as string)
              }
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              onClick={createSortHandler(headCell.id)}
              align="left"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: headCell.headerAlign,
                }}
              >
                <span
                  style={{
                    marginRight: "0.5rem",
                    fontWeight: orderBy === headCell.id ? 600 : "normal",
                  }}
                >
                  {headCell.label}
                </span>
                {orderBy === headCell.id ? (
                  order === "desc" ? (
                    <ExpandMore fontSize="medium" />
                  ) : (
                    <ExpandLess fontSize="medium" />
                  )
                ) : null}
              </Box>
            </TableCell>
          )
        )}
        {moreOptionsMenuExists && <TableCell align="right"></TableCell>}
      </TableRow>
    </TableHead>
  );
};

interface DashboardTableProps<DataRowType> {
  // open: boolean;
  // drawerWidth?: number;
  dataRows: DataRowType[] | null;
  headCells: DashboardHeadCell<DataRowType>[];
  fuseThreshold: number;
  noRowsMessage?: {
    title: string;
    subtitle: string;
  };
  headerButtonData?: HeaderButtonDataProps;
  headerTitleData?: {
    title: string;
    subtitle: string;
  };
  handleOnRowClick: (id: string) => void;
  moreOptionsMenu?: any;
  hideHeader?: boolean;
  minTableHeight?: string;
  maxTableHeight?: string;
}

const DashboardTable = <DataRowType extends { _id: string }>({
  dataRows,
  headCells,
  fuseThreshold,
  noRowsMessage,
  headerButtonData,
  handleOnRowClick,
  headerTitleData,
  moreOptionsMenu,
  hideHeader,
  minTableHeight,
  maxTableHeight,
}: DashboardTableProps<DataRowType>) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRowType | null>(null);
  const [selected] = useState<string[]>([]);

  const isSelected = (propertyId: string) =>
    selected.indexOf(propertyId) !== -1;

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSearchColumn, setSelectedSearchColumn] =
    useState<string>("all");

  const handleRequestSort = (property: keyof DataRowType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedFilteredRows = useMemo(() => {
    const sortData = (a: DataRowType, b: DataRowType) => {
      if (orderBy && orderBy in a) {
        if (order === "asc") {
          return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
          return a[orderBy] > b[orderBy] ? -1 : 1;
        }
      }
      return 0;
    };

    if (!dataRows) {
      return [];
    }

    let rows = [...dataRows];
    if (searchTerm) {
      const searchKeys = headCells
        .filter((headCell) => headCell.isSearchable)
        .map((headCell) => {
          // Handle special case for nested user keys or address
          if (headCell.id === "user") {
            return [
              "user.firstName",
              "user.lastName",
              "user.username",
              "user._id",
              "entity.name",
            ];
          } else if (headCell.id === "address") {
            return [
              "address.streetLine1",
              "address.city",
              "address.state",
              "address.zip",
            ];
          }
          return headCell.id as string;
        })
        .flat(); // Flatten the array in case of nested keys

      const updatedFuseKeys =
        selectedSearchColumn === "all" ? searchKeys : [selectedSearchColumn];

      const fuseOptions = {
        keys: updatedFuseKeys, // Columns to search in
        threshold: fuseThreshold, // Adjust this for more or less strict matching
      };

      const fuse = new Fuse(dataRows, fuseOptions);
      rows = fuse.search(searchTerm).map((result) => result.item);
    }

    return rows.sort(sortData);
  }, [
    searchTerm,
    dataRows,
    orderBy,
    order,
    selectedSearchColumn,
    fuseThreshold,
    headCells,
  ]);

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="space-between"
      rowSpacing={4.5}
      columnSpacing={2.75}
    >
      {!hideHeader && (
        <Grid item xs={12}>
          <GenericHeader
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedSearchColumn={selectedSearchColumn}
            setSelectedSearchColumn={setSelectedSearchColumn}
            headCells={headCells}
            headerButtonData={headerButtonData}
            headerTitleData={headerTitleData}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer
          sx={{
            width: "100%",
            overflowX: "auto",
            position: "relative",
            display: "block",
            maxWidth: "100%",
            "& td, & th": { whiteSpace: "nowrap" },
            borderRadius: "8px",
            border: "1px solid #D5DAE2", // This will give each cell a border
            backgroundColor: "background.paper",
            borderColor: "backgroundMoreColors.default",
            cursor: "pointer",

            minHeight: minTableHeight ?? "400px", // Minimum height to ensure container does not look too short
            // especially when the rows are loading
            ...(maxTableHeight && { maxHeight: maxTableHeight }),
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            sx={{
              "& .MuiTableCell-root:first-of-type": {
                pl: 2,
              },
              "& .MuiTableCell-root:last-of-type": {
                pr: 3,
              },
            }}
          >
            <TableHeader
              order={order}
              orderBy={orderBy as string}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              moreOptionsMenuExists={!!moreOptionsMenu}
            />
            <TableBody>
              {dataRows !== null &&
              sortedFilteredRows.length === 0 &&
              searchTerm === "" ? (
                <TableRow>
                  <TableCell
                    colSpan={headCells.length}
                    align="center"
                    sx={{
                      height: "200px",
                      border: "none",
                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                        {noRowsMessage?.title ?? "No data found"}
                      </span>
                      <span
                        style={{ fontSize: "1.25rem", marginTop: "0.5rem" }}
                      >
                        {noRowsMessage?.subtitle ??
                          "Contact support if you need help getting started."}
                      </span>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                sortedFilteredRows.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      onClick={() => handleOnRowClick(row._id)}
                    >
                      {headCells.map((headCell) => (
                        <Fragment
                          key={
                            headCell.columnKey
                              ? headCell.columnKey
                              : (headCell.id as string)
                          }
                        >
                          {headCell.customCell ? (
                            headCell.customCell(
                              row[headCell.id],
                              row._id as string
                            ) //  'row' is the current item in your data array
                          ) : (
                            <TableCell
                              align={headCell.align ?? "left"}
                              component={
                                headCell.id === "_id" ? "th" : undefined
                              }
                              id={headCell.id === "_id" ? labelId : undefined}
                              scope={headCell.id === "_id" ? "row" : undefined}
                              sx={{
                                color: "text.primary",
                              }}
                            >
                              {
                                row[
                                  headCell.id as keyof typeof row
                                ] as React.ReactNode
                              }
                            </TableCell>
                          )}
                        </Fragment>
                      ))}
                      {moreOptionsMenu && (
                        <TableCell align="right">
                          <IconButton
                            aria-label="more options"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents the row click event
                              // Handle your menu opening logic here, for example:
                              // openMenu(event, row._id);
                            }}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default DashboardTable;
