import React from "react";
import RoomImagesGrid from "./RoomImagesGrid";
import { Grid } from "@mui/material";
import { InspectionDocument, RoomInspectionsData } from "../../constants/types";

interface ImageItem {
  img: string;
  title: string;
}

interface InspectionsRoomsGridProps {
  imageList?: ImageItem[];
  roomName?: string;
  inspectionImagesRooms: RoomInspectionsData[] | null;
  propertyInspectionsData: InspectionDocument[];
}

const InspectionsRoomsGrid: React.FC<InspectionsRoomsGridProps> = ({
  imageList,
  roomName,
  inspectionImagesRooms,
  propertyInspectionsData,
}) => {
  return (
    <Grid container rowSpacing={6.75} columnSpacing={2.75}>
      {inspectionImagesRooms &&
        inspectionImagesRooms.map((roomInspection, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={4}>
            <RoomImagesGrid
              roomData={roomInspection.room}
              imageList={roomInspection.inspectionImages}
              propertyInspectionsData={propertyInspectionsData}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default InspectionsRoomsGrid;
