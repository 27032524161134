// Navbar.tsx
import React, { useEffect, useRef } from "react";
import { Toolbar, IconButton, Badge, styled, Avatar } from "@mui/material";

import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { deepPurple, green } from "@mui/material/colors";
import profilePopper from "./ProfilePopper";
import { NotificationDocument } from "../../constants/types";
import { getNotifications } from "../../services/apiHelper/notifications";
import { NotificationMenu } from "./NotificationPopper";
import { useLocation } from "react-router-dom";

interface NavbarProps {
  username?: string;
  open: boolean;
  drawerWidth?: number;
  handleDrawerToggle: () => void;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerWidth: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})<AppBarProps>(({ theme, open, drawerWidth }) => ({
  backgroundColor: theme.palette.background.paper, // This sets the background to light gray from the theme's palette

  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "none",
  borderBottom: "1px solid",
  borderColor: theme.palette.secondary.light,

  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar: React.FC<NavbarProps> = ({
  username = "",
  open,
  drawerWidth = 240,
  handleDrawerToggle,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorElNotofication, setAnchorElNotification] =
    React.useState<HTMLButtonElement | null>(null);
  const profileButtonRef = useRef<HTMLButtonElement>(null);
  const notificationButtonRef = useRef<HTMLButtonElement>(null);

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [notificationsData, setNotificationsData] = React.useState<
    NotificationDocument[]
  >([]);
  const [notificationsUnreadCount, setNotificationsUnreadCount] =
    React.useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    getNotifications({}).then((res) => {
      setNotificationsData(res);
    });
  }, [location]);
  useEffect(() => {
    if (notificationsData) {
      setNotificationsUnreadCount(
        notificationsData.filter((notification) => !notification.read).length
      );
    }
  }, [notificationsData]);

  const handleClickProfilePopper = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen2((prev) => !prev);
  };

  const handleClickNotificationPopper = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNotification(event.currentTarget);
    setOpen3((prev) => !prev);
  };

  const handleCloseProfilePopper = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => {
    if (
      profileButtonRef.current &&
      profileButtonRef.current.contains(event.target as Node)
    ) {
      return;
    }

    setOpen2(false);
  };
  const handleCloseNotificationPopper = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
      | null
  ) => {
    if (
      event === null ||
      (notificationButtonRef.current &&
        notificationButtonRef.current.contains(event.target as Node))
    ) {
      return;
    }

    setOpen3(false);
  };

  return (
    <AppBar position="fixed" open={open} drawerWidth={drawerWidth}>
      {profilePopper({
        open: open2,
        handleClosePopper: handleCloseProfilePopper,
        anchorEl: anchorEl,
        username: username,
      })}
      <NotificationMenu
        notifications={notificationsData}
        setNotificationsData={setNotificationsData}
        open={open3}
        handleClose={handleCloseNotificationPopper}
        anchorEl={anchorElNotofication}
      />

      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          color="default"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{ mr: 2, color: (theme) => theme.palette.text.primary }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            color="default"
            sx={{ mr: 2, color: (theme) => theme.palette.text.primary }}
            onClick={handleClickNotificationPopper}
            ref={notificationButtonRef}
          >
            {/* todo update badge number */}
            <Badge badgeContent={notificationsUnreadCount} color="info">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            color="default"
            onClick={handleClickProfilePopper}
            sx={{ mr: 2 }}
            ref={profileButtonRef}
          >
            <Avatar sx={{ bgcolor: green[700] }}>
              {username ? username.charAt(0).toUpperCase() : ""}
            </Avatar>
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
