import React from "react";
import { Button, TableCell, CircularProgress } from "@mui/material";
import { InsertDriveFile, CloudUpload } from "@mui/icons-material";
import { Attachment } from "../../../../constants/types";
import { cacheOrRequestMediaFromBackend } from "../../../../utils/imageUtils";

type ScopeOfWorkProps = {
  fileUrl: Attachment | null;
  propertyAddress: string;
  onUpload: () => void;
  isLoading?: boolean;
};

export const ScopeOfWork: React.FC<ScopeOfWorkProps> = ({
  fileUrl,
  propertyAddress,
  onUpload,
  isLoading,
}) => {
  const handleViewClick = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (fileUrl) {
      const newFileName = `${propertyAddress} - Scope of Work.pdf`;
      const signedUrl = await cacheOrRequestMediaFromBackend(
        fileUrl?.path,
        newFileName
      );
      if (signedUrl) window.open(signedUrl, "_blank");
    }
  };

  const handleUploadClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onUpload();
  };

  if (isLoading) {
    return (
      <TableCell align="center" padding="none">
        <CircularProgress size={24} />
      </TableCell>
    );
  }

  return (
    <TableCell align="center" padding="none">
      {fileUrl ? (
        <>
          <Button
            startIcon={<InsertDriveFile color="secondary" />}
            onClick={handleViewClick}
          >
            View
          </Button>
          <Button
            style={{ color: "#888888", marginLeft: 20 }}
            onClick={handleUploadClick}
          >
            Replace
          </Button>
        </>
      ) : (
        <Button
          startIcon={<CloudUpload color="secondary" />}
          onClick={handleUploadClick}
        >
          Upload
        </Button>
      )}
    </TableCell>
  );
};
