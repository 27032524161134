import React, { useState, useEffect } from "react";
import {
  cacheOrRequestMediaFromBackend,
  getMimeType,
} from "../../../utils/imageUtils";

interface CustomImageV2Props {
  image: string;
  imageStyle?: React.CSSProperties;
  badge?: React.ReactNode;
  isLocalImageURI?: boolean;
  alt?: string;
  shouldPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
}

export const CustomImageV2: React.FC<CustomImageV2Props> = ({
  image,
  imageStyle,
  badge,
  isLocalImageURI,
  alt = "",
  shouldPlay = false,
  loop = false,
  controls = true,
}) => {
  const [mediaUri, setMediaUri] = useState<string | null>(null);
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    const getMediaUri = async (uri: string) => {
      const mimeType = getMimeType(uri);
      const isMediaVideo = mimeType.startsWith("video/"); // Check if the mime type indicates a video
      setIsVideo(isMediaVideo);

      const localUri = await cacheOrRequestMediaFromBackend(uri);
      setMediaUri(localUri);
    };

    if (image) {
      if (!isLocalImageURI) {
        getMediaUri(image);
      } else {
        setMediaUri(image); // Directly set the local URI if it's a local image
      }
    }
  }, [image, isLocalImageURI]);

  return (
    <div style={{ ...styles.container }}>
      {mediaUri ? (
        isVideo ? (
          <video
            src={mediaUri}
            style={{
              ...styles.media,
              ...imageStyle,
            }}
            autoPlay={shouldPlay}
            loop={loop}
            controls={controls}
          />
        ) : (
          <img
            src={mediaUri}
            alt={alt}
            style={{
              ...styles.media,
              ...imageStyle,
            }}
          />
        )
      ) : (
        <div style={{ ...styles.imagePlaceholder, paddingTop: "100%" }} />
      )}
      {badge && (
        <div style={{ position: "absolute", bottom: 0, right: 0 }}>{badge}</div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  media: {
    width: 150,
    height: 150,
  },
  imagePlaceholder: {
    width: "100%",
    height: "100%",
    backgroundColor: "#CCC",
    opacity: 0.4,
  },
};
