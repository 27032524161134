import React, { useRef } from "react";
import { TableCell, TextField } from "@mui/material";

export const TextInputCol: React.FC<{
  inputText?: string;
  _id?: string;
  handleEditNotes: () => void;
}> = ({ inputText = "", _id, handleEditNotes }) => {
  const textFieldRef = useRef(null);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {};

  return (
    <TableCell
      align="center"
      onClick={(event) => {
        handleEditNotes();
        event.stopPropagation(); // Prevents the row click event
      }}
    >
      <TextField
        id={`outlined-basic-${_id}`}
        variant="outlined"
        size="small"
        value={inputText || ""}
        onChange={handleTextChange}
        ref={textFieldRef}
        InputProps={{
          readOnly: true,
        }}
      />
    </TableCell>
  );
};
