import React from "react";
import { ImageDocument, ImageViewType } from "../../constants/types";
import { InspectionImagesGroupedData } from "../../screens/InspectionImages/InspectionImages";
import { ImageGroupWithHeader } from "./ImageGroupWithHeader";
import { useLocation, useNavigate } from "react-router-dom";

interface InspectionsPhotoLibraryProps {
  imageViewType: ImageViewType;
  inspectionImagesGroupedData?: InspectionImagesGroupedData[];
  roomName?: string;
  urlParams: {
    roomId_param: string | null;
    inspId_param: string | null;
  };
}

const InspectionsPhotoLibrary: React.FC<InspectionsPhotoLibraryProps> = ({
  inspectionImagesGroupedData,
  urlParams,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpen = (imgSrc: ImageDocument) => {
    const navigateToImage = () => {
      const roomOrInsp = urlParams.roomId_param ? "roomId" : "inspId";

      const roomOrInspId = urlParams.roomId_param
        ? urlParams.roomId_param
        : urlParams.inspId_param;

      const fullPath = location.pathname; // we are using full path instead of base path
      // because we also want to keep the path params
      const newPath = `${fullPath}/img?${roomOrInsp}=${roomOrInspId}&img=${imgSrc._id}`;
      navigate(newPath);
    };

    navigateToImage();
  };

  return (
    <>
      {inspectionImagesGroupedData &&
        inspectionImagesGroupedData.map((group, index) => (
          <ImageGroupWithHeader
            imageBatch={group.data}
            imgBatchDescription={group.title}
            key={index}
            handleOpen={handleOpen}
          />
        ))}
    </>
  );
};

export default InspectionsPhotoLibrary;
