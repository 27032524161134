import React, { useEffect } from "react";
import { Box, Button, CssBaseline, Link, Typography } from "@mui/material";
import { LogoutOutlined } from "@mui/icons-material";
import { Auth } from "aws-amplify";
import { ColorMode } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { getSelf } from "../../services/apiHelper/users";

export function NonAuthorizedUser() {
  const [colorMode, setColorMode] = React.useState<ColorMode>("system");
  const [email, setEmail] = React.useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // if not auth current user, redirect to login
    Auth.currentAuthenticatedUser()
      .then((user) => {
        getSelf().then((res) => {
          setEmail(res?.userData?.email);
        });
      })
      .catch(() => navigate("/gpc"));
  }, [navigate]);

  const logoSrc =
    colorMode === "dark"
      ? "https://pikcheck-static-files.s3.amazonaws.com/logo-images/logo_wide_v1_dm.png"
      : "https://pikcheck-static-files.s3.amazonaws.com/logo-images/logo_wide_v1_lm.png";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "background.paper",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        px: 2,
      }}
    >
      <CssBaseline />
      <img
        src={logoSrc}
        alt="Logo"
        style={{ maxWidth: "60%", height: "auto", marginBottom: "2rem" }}
      />
      <Typography variant="h5" gutterBottom mt={4}>
        GeoSpec Web is for Lenders
      </Typography>
      <Typography variant="body1" gutterBottom>
        If you are part of an organization and need more information, contact us
        at{" "}
        <Link href="mailto:support@geovantllc.com" color="primary">
          support@geovantllc.com
        </Link>{" "}
        or visit{" "}
        <Link
          href="https://www.geospecme.com/"
          target="_blank"
          rel="noopener"
          color="primary"
        >
          www.geospecme.com
        </Link>
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
        <Link
          href="https://apps.apple.com/us/app/pikcheck/id6471804717"
          target="_blank"
          rel="noopener"
        >
          <img
            src="https://pikcheck-static-files.s3.amazonaws.com/logo-images/download_app_store_US.png"
            alt="App Store"
            style={{ height: "50px", marginRight: "10px" }}
          />
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.nerdevs.pikcheck"
          target="_blank"
          rel="noopener"
        >
          <img
            src="https://pikcheck-static-files.s3.amazonaws.com/logo-images/google-play-badge_US.png"
            alt="Google Play Store"
            style={{ height: "50px", marginLeft: "10px" }}
          />
        </Link>
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<LogoutOutlined />}
        sx={{ mt: 10 }}
        onClick={() => {
          Auth.signOut()
            .then(() => {
              navigate("/gpc");
            })
            .catch((err) => console.log(err));
        }}
      >
        Switch Accounts
      </Button>
      <Typography variant="h5" gutterBottom mt={4}>
        You are currently logged in as "{email}"
      </Typography>
    </Box>
  );
}
