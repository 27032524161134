// modify  the file @types/react-app-env.d.ts

export const AWS_COGNITO_REGION: string | undefined =
  process.env.REACT_APP_AWS_COGNITO_DEFAULT_REGION;
export const AWS_COGNITO_CLIENT_ID: string | undefined =
  process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
export const AWS_COGNITO_USER_POOL_ID: string | undefined =
  process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const GOOGLE_MAPS_AUTOCOMPLETE_API_KEY: string | undefined =
  process.env.REACT_APP_PIKCHECK_GOOGLE_MAPS_AUTOCOMPLETE_API_KEY;

export const BACKEND_HOST: string =
  process.env.REACT_APP_BACKEND_HOST || "http://localhost:80";

export const BASEPATHS: Record<string, string> = {
  user: "user",
  lendingCompany: "lendingCompany",
  property: "property",
  inspection: "inspection",
  general: "general",
  notification: "notification",
};

export const USER_TYPES: Record<string, number> = {
  LENDER: 0,
  BORROWER: 1,
};

export const USER_TYPES_STRING: Record<number, string> = {
  [USER_TYPES.LENDER]: "Lender",
  [USER_TYPES.BORROWER]: "Borrower",
};
