import React, { useEffect, useMemo, useState } from "react";

import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  DashboardHeadCell,
  HeaderButtonDataProps,
  LayoutContextType,
  PropertyDocument,
} from "../../../constants/types";
import { Box } from "@mui/material";
import DashboardTable from "../../../components/common/dashboard/DashboardTable";
import { AddHomeWork, Settings } from "@mui/icons-material";
import {
  Main,
  DrawerHeader,
} from "../../../components/properties/common/LayoutComponents";
import { CheckInStatus } from "../../../components/common/dashboard/DashCustomCols/CheckInStatus";
import { InspectionAlerts } from "../../../components/common/dashboard/DashCustomCols/InspectionAlerts";
import { AddressCol } from "../../../components/common/dashboard/DashCustomCols/AddressCol";
import { TextInputCol } from "../../../components/common/dashboard/DashCustomCols/TextInputCol";
import {
  getLCProperties,
  updateProperty,
} from "../../../services/apiHelper/properties";
import { useSelector } from "react-redux";
import { selectUsersData } from "../../../ts/selectors";
import {
  getUsers,
  updateLCUser,
  UpdateUser_API_CALL,
} from "../../../services/apiHelper/users";
import GenericHeader, {
  GenericHeaderTitleDataProps,
} from "../../../components/common/dashboard/GenericHeader";
import { NotesSidebar } from "../../../components/common/Notes/notesSidebar";
import { useBasePath } from "../../../utils/navigation.util";
import CustomBreadcrumbs from "../../../components/navbar/CustomBreadcrumbs";
import NotesDisplay from "../../../components/common/Notes/NotesDisplay";
import { USER_TYPES, USER_TYPES_STRING } from "../../../config";
interface IndividualUserProps {
  // You can define props here if needed. For this simple example, it's empty.
}

const IndividualUser: React.FC<IndividualUserProps> = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();
  const [isPropSidebarOpen, setIsPropSidebarOpen] =
    React.useState<boolean>(false);
  const [isUserSidebarOpen, setIsUserSidebarOpen] =
    React.useState<boolean>(false);

  const [notesPropertyId, setNotesPropertyId] = React.useState<String>("");
  const [textInput, setTextInput] = React.useState<string>("");

  const navigate = useNavigate();
  const basePath = useBasePath();
  const { userId: userIdParam } = useParams<{ userId?: string }>();
  const userId = userIdParam ?? "0";

  const [headerTitleData, setHeaderTitleData] = useState<
    GenericHeaderTitleDataProps | undefined
  >({ title: "", subtitle: "" });

  const [dataRows, setDataRows] = React.useState<PropertyDocument[]>([]);
  const [usersDataRows, setUsersDataRows] = React.useState<
    PropertyDocument[] | null
  >(null);
  const [error, setError] = useState<string | null>(null); // State to store the error message

  const usersData = useSelector(selectUsersData);

  const headCells: DashboardHeadCell<PropertyDocument>[] = [
    {
      id: "address",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "Address",
      customCell: (address) => <AddressCol addressVal={address} />,
    },
    // todo - add back in when we have a status from backend
    // {
    //   id: "alertType",
    //   align: "center",
    //   headerAlign: "center",
    //   disablePadding: false,
    //   label: "Alerts",
    //   customCell: (value) => <InspectionAlerts alertType={value} />,
    // },
    // {
    //   id: "status",
    //   align: "left",
    //   headerAlign: "center",
    //   disablePadding: false,
    //   label: "Status",
    //   customCell: (value) => <CheckInStatus status={value} />,
    // },
    {
      id: "notes",
      align: "left",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: false,
      label: "Notes",
      customCell: (value, _id) => {
        return (
          <TextInputCol
            inputText={value}
            _id={_id}
            handleEditNotes={() => {
              setIsPropSidebarOpen(true);
              if (_id) setNotesPropertyId(_id);
              setTextInput(value);
            }}
          />
        );
      },
    },
  ];

  const closeSidebar = () => {
    setIsPropSidebarOpen(false);
    setIsUserSidebarOpen(false);
    setTextInput("");
  };

  const handleUpdateUserNotes = async (notes: string) => {
    const fieldsToUpdate: UpdateUser_API_CALL = {
      _id: filteredUser?._id,
      notes,
    };

    try {
      setError(null);
      await updateLCUser(fieldsToUpdate);
      const res = await getUsers();
      const filteredUsers = res.users.filter(
        (user: { userType: number }) => user.userType !== 0
      );
      setDataRows(filteredUsers);
      return true;
    } catch (e) {
      setError("Error updating user notes");
    }
  };

  const handleUpdatePropertyNotes = async (notes: string) => {
    if (!notesPropertyId) return;

    try {
      setError(null);

      await updateProperty(notesPropertyId, { notes });
      getLCProperties().then((res) => {
        setDataRows(res.properties);
      });
      return true;
    } catch (e) {
      setError("Error updating property notes");
    }
  };

  const filteredUser = useMemo(() => {
    return usersData?.find((user) => user._id === userId) ?? null;
  }, [usersData, userId]);

  useEffect(() => {
    getLCProperties().then((res) => {
      setDataRows(res.properties);
    });
    if (!usersData) {
      getUsers();
    }
  }, [usersData]);

  useEffect(() => {
    if (filteredUser) {
      setHeaderTitleData({
        title: filteredUser
          ? `${filteredUser.firstName || ""} ${
              filteredUser.lastName || ""
            }`.trim() || filteredUser.email
          : "Unknown User",
        subtitle: USER_TYPES_STRING[filteredUser.userType],
      });

      if (dataRows.length > 0) {
        const usersProperties = filteredUser.propertyList;

        const filteredDataRows = dataRows.filter((property) =>
          usersProperties.includes(property._id)
        );
        setUsersDataRows(filteredDataRows);
      }
    }
  }, [dataRows, filteredUser]);

  const handleOnRowClick = (id: string) => {
    const newPath = `${basePath}/p/0/${id}`;
    navigate(newPath);
  };

  const handleAddPropertyButtonClick = () => {
    if (filteredUser?.userType === USER_TYPES.LENDER)
      navigate(`/gpc/ml/${userId}/ap`);
    else navigate(`/gpc/mb/${userId}/ap`);
  };

  const handleUpdateUserButtonClick = () => {
    if (filteredUser?.userType === USER_TYPES.LENDER)
      navigate(`/gpc/ml/${userId}/uu`);
    else navigate(`/gpc/mb/${userId}/uu`);
  };

  const updateUserButtonData: HeaderButtonDataProps = {
    label: "Update User",
    startIcon: <Settings />,
    handleClick: handleUpdateUserButtonClick,
  };

  const addPropertyButtonData: HeaderButtonDataProps = {
    label: "Add Property",
    startIcon: <AddHomeWork />,
    handleClick: handleAddPropertyButtonClick,
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
        }}
      >
        <Main open={open} drawerWidth={drawerWidth}>
          <DrawerHeader />
          <CustomBreadcrumbs />

          <Box sx={{ mb: 5 }}>
            <GenericHeader
              headerTitleData={headerTitleData}
              headCells={headCells}
              headerButtonData={updateUserButtonData}
            />
            <NotesDisplay
              openSidebar={() => {
                setIsUserSidebarOpen(true);
                setTextInput(filteredUser?.notes ?? "");
              }}
              notes={filteredUser?.notes}
              title="Private Borrower Notes (Visible Only to Lenders)"
              alwaysShowTitle={true}
              mt={2}
            />
          </Box>

          <DashboardTable
            dataRows={usersDataRows}
            headCells={headCells}
            fuseThreshold={0.4}
            handleOnRowClick={handleOnRowClick}
            headerButtonData={addPropertyButtonData}
            noRowsMessage={{
              title: "No properties yet",
              subtitle: "Add a property to get started.",
            }}
            minTableHeight="250px"
          />
          <NotesSidebar
            isSidebarOpen={isPropSidebarOpen || isUserSidebarOpen}
            initialText={textInput}
            handleClose={closeSidebar}
            error={error}
            handleSubmit={
              isPropSidebarOpen
                ? handleUpdatePropertyNotes
                : handleUpdateUserNotes
            }
          />
        </Main>
      </Box>
    </>
  );
};

export default IndividualUser;
