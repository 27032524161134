import React from "react";
import { AddLocationAltOutlined } from "@mui/icons-material";
import { Grid, Button, MenuItem, Select, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import AutocompleteAddress from "../properties/common/AutocompleteAddressInput";
import { PropertyDataWithMapAPI } from "../../constants/types";

interface AddPropertiesProps {
  startDateDate: {
    selectedDate: Date | null;
    setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  };
  inspectionFrequencyData: {
    inspectionFrequency: string;
    setFrequency: React.Dispatch<React.SetStateAction<string>>;
  };
  propertiesStateData: {
    properties: PropertyDataWithMapAPI[];
    setProperties: React.Dispatch<
      React.SetStateAction<PropertyDataWithMapAPI[]>
    >;
  };
  defaultOneProperty?: boolean;
}

const AddProperties: React.FC<AddPropertiesProps> = ({
  startDateDate,
  inspectionFrequencyData,
  propertiesStateData,
  defaultOneProperty = false,
}) => {
  const handleAddProperty = () => {
    propertiesStateData.setProperties((prevProperties) => [
      ...prevProperties,
      { id: Date.now(), address: null, loanEndDate: null, loanAmount: null },
    ]);
  };

  const handleDeleteProperty = (id: number) => {
    propertiesStateData.setProperties((prev) =>
      prev.filter((property) => property.id !== id)
    );
  };

  return (
    <Grid container item rowSpacing={2} columnSpacing={2.75} xs={12}>
      {/* {!defaultOneProperty && (
        <Grid item xs={12}>
          <Button
            startIcon={<AddLocationAltOutlined />}
            variant="contained"
            color="success"
            size="medium"
            onClick={handleAddProperty}
            disableRipple
          >
            Add Property
          </Button>
        </Grid>
      )} */}
      {/* TODO add frquency later */}
      {/* Inspection Frequency */}
      {/* <Grid item xs={12} sm={8} md={7} lg={5}>
        <Select
          variant="outlined"
          fullWidth
          value={inspectionFrequencyData.inspectionFrequency}
          onChange={(event) =>
            inspectionFrequencyData.setFrequency(event.target.value)
          }
          displayEmpty
          disabled={propertiesStateData.properties.length === 0}
          sx={[
            { color: "text.primary" },
            propertiesStateData.properties.length === 0
              ? { backgroundColor: "backgroundMoreColors.paper" }
              : {},
          ]}
        >
          <MenuItem value="" disabled>
            How often do you want an inspection
          </MenuItem>
          <MenuItem value="2-weeks">2 Weeks</MenuItem>
          <MenuItem value="4-weeks">4 Weeks</MenuItem>
          <MenuItem value="6-weeks">6 Weeks</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={9} md={6} lg={4}>
        <DatePicker
          label="First inspection date"
          value={startDateDate.selectedDate}
          onChange={(newValue) => startDateDate.setDate(newValue)}
          disablePast
          disabled={propertiesStateData.properties.length === 0}
          sx={
            propertiesStateData.properties.length === 0
              ? { backgroundColor: "backgroundMoreColors.paper" }
              : {}
          }
        />
      </Grid> */}

      <Grid container item xs={12} rowSpacing={2}>
        {propertiesStateData.properties.length === 0 ? (
          // Grayed out property
          // todo add back add properties using map logic
          <></>
        ) : (
          // <Grid item sm={12} md={6} lg={5}>
          //   <TextField
          //     label="Property Address"
          //     variant="outlined"
          //     fullWidth
          //     disabled
          //     sx={
          //       propertiesStateData.properties.length === 0
          //         ? { backgroundColor: "backgroundMoreColors.paper" }
          //         : {}
          //     }
          //   />
          // </Grid>
          propertiesStateData.properties.map((property, index) => {
            return (
              <AutocompleteAddress
                key={property.id}
                property={property}
                updateAddress={(id, address) => {
                  propertiesStateData.setProperties((prevProperties) =>
                    prevProperties.map((prop) =>
                      prop.id === id ? { ...prop, address } : prop
                    )
                  );
                }}
                updateLoanEndDate={(id, loanEndDate) => {
                  propertiesStateData.setProperties((prevProperties) =>
                    prevProperties.map((prop) =>
                      prop.id === id ? { ...prop, loanEndDate } : prop
                    )
                  );
                }}
                updateLoanAmount={(id, loanAmount) => {
                  propertiesStateData.setProperties((prevProperties) =>
                    prevProperties.map((prop) =>
                      prop.id === id ? { ...prop, loanAmount } : prop
                    )
                  );
                }}
                onDelete={handleDeleteProperty}
                defaultOneProperty={defaultOneProperty}
              />
            );
          })
        )}
      </Grid>
    </Grid>
  );
};

export default AddProperties;
