import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { TableCell, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";

type ThreeDotMenuProps = {
  userId: string;
  isUserLender?: boolean;
  handleResetPassword?: (userId: string) => void;
  handleUpdateUser?: (userId: string) => void;
  handleDeleteUser?: (userId: string) => void;
  handleResendInvitation?: (userId: string) => void;
  handleRemoveUserFromProperty?: (userId: string) => void;
};

function ThreeDotMenu({
  userId,
  isUserLender,
  handleResetPassword,
  handleUpdateUser,
  handleDeleteUser,
  handleResendInvitation,
  handleRemoveUserFromProperty,
}: ThreeDotMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleResetPasswordClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (handleResetPassword) handleResetPassword(userId);
    event.stopPropagation();
  };

  const handleDeleteUserClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (handleDeleteUser) handleDeleteUser(userId);
    event.stopPropagation();
  };

  const handleUpdateUserClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (handleUpdateUser) handleUpdateUser(userId);
    event.stopPropagation();
  };

  const handleResendInvitationClick = (
    event: React.MouseEvent<HTMLLIElement>
  ) => {
    if (handleResendInvitation) handleResendInvitation(userId);
    event.stopPropagation();
  };

  const handleRemoveUserFromPropertyClick = (
    event: React.MouseEvent<HTMLLIElement>
  ) => {
    if (handleRemoveUserFromProperty) handleRemoveUserFromProperty(userId);
    event.stopPropagation();
  };

  const CustomMenuItem = ({
    menuaTitle,
    disabledText,
    color,
    isUserAdmin = false,
    onClick,
  }: {
    menuaTitle: string;
    disabledText?: string;
    color?: string;
    isUserAdmin?: boolean;
    onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  }) => {
    return (
      <Tooltip
        title={<h2 style={{ color: grey[300] }}>{disabledText}</h2>}
        placement="top"
        arrow
        disableHoverListener={!isUserAdmin}
      >
        <MenuItem
          onClick={onClick}
          disabled={isUserAdmin}
          style={{ pointerEvents: isUserAdmin ? "none" : "auto", color: color }}
        >
          {menuaTitle}
        </MenuItem>
      </Tooltip>
    );
  };

  return (
    <TableCell align="right" sx={{ padding: 0 }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          paddingX: "20px",
          borderRadius: "5%",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {handleUpdateUser && (
          <CustomMenuItem
            menuaTitle="Update User"
            isUserAdmin={false}
            disabledText="Cannot update user"
            onClick={handleUpdateUserClick}
          />
        )}
        {handleResetPassword && (
          <CustomMenuItem
            menuaTitle="Reset Password"
            // Placeholder value add to backend specific admin lenders that manage other lenders
            isUserAdmin={false}
            disabledText="Cannot reset password for other admin users"
            onClick={handleResetPasswordClick}
          />
        )}

        {handleDeleteUser && (
          <CustomMenuItem
            menuaTitle="Delete User"
            isUserAdmin={false}
            disabledText="Cannot delete user"
            color="red"
            onClick={handleDeleteUserClick}
          />
        )}

        {handleRemoveUserFromProperty && (
          <CustomMenuItem
            menuaTitle="Remove User from Property"
            isUserAdmin={isUserLender}
            disabledText="Cannot remove other lenders from property"
            onClick={handleRemoveUserFromPropertyClick}
          />
        )}
        {handleResendInvitation && (
          <CustomMenuItem
            menuaTitle="Resend Invitation"
            isUserAdmin={isUserLender}
            disabledText="Cannot resend invitation. User already activated their account."
            onClick={handleResendInvitationClick}
          />
        )}
      </Menu>
    </TableCell>
  );
}

export default ThreeDotMenu;
