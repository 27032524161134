// Navbar.tsx
import React from "react";
import {
  Fade,
  Typography,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Box,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import { LogoutOutlined } from "@mui/icons-material";
import { Auth } from "aws-amplify";

const profilePopper = ({
  open,
  handleClosePopper,
  anchorEl,
  username,
}: {
  open: boolean;
  handleClosePopper: (event: any) => void;
  anchorEl: HTMLElement | null;
  username: string;
}) => {
  return (
    <ClickAwayListener onClickAway={handleClosePopper}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        sx={{ zIndex: 1210 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card
              sx={{
                p: 2,
                borderRadius: 3,
                zIndex: 10,
                boxShadow: 7,
              }}
            >
              <CardContent>
                <Box mb={2}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "text.primary",
                    }}
                  >
                    {username}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<LogoutOutlined />}
                  onClick={() => {
                    Auth.signOut()
                      .then(() => {})
                      .catch((err) => console.log(err));
                  }}
                >
                  Sign Out
                </Button>
              </CardContent>
            </Card>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default profilePopper;
