import React from "react";
import {
  ImageDocument,
  ImageViewType,
  InspectionDocument,
  InspectionImageDocument,
  RoomDocument,
} from "../../constants/types";
import { InspectionImagesGroupedData } from "../../screens/InspectionImages/InspectionImages";
import { getInspectionImages } from "../../services/apiHelper/inspections";
import store from "../../ts/store";

type LoadInspectionImagesProps = {
  roomId?: string;
  inspId?: string;
  propertyRoomsData?: RoomDocument[] | null;
  propertyInspectionsData?: InspectionDocument[] | null;
  setInspectionImagesGrouped?: React.Dispatch<
    React.SetStateAction<InspectionImagesGroupedData[]>
  >;
};

function convertToImageDocumentArray(
  groupedData: InspectionImagesGroupedData[]
): InspectionImageDocument[] {
  return groupedData.flatMap((item) => item.data);
}

export const loadInspectionImages = async ({
  roomId,
  inspId,
  propertyRoomsData,
  propertyInspectionsData,
  setInspectionImagesGrouped,
}: LoadInspectionImagesProps) => {
  let imageViewType: ImageViewType;
  let inspectionRoomImagesData: InspectionImageDocument[];

  if (roomId) {
    inspectionRoomImagesData = await getInspectionImages({ roomId: roomId });
    imageViewType = ImageViewType.ByDate;
  } else if (inspId) {
    inspectionRoomImagesData = await getInspectionImages({
      inspectionId: inspId,
    });
    imageViewType = ImageViewType.ByRoomName;
  } else return;
  // Initialize an empty object to store grouped sections
  const groupedData: { [key: string]: InspectionImagesGroupedData } = {};

  inspectionRoomImagesData.forEach((item: InspectionImageDocument) => {
    let inspectionId: string;
    if (imageViewType === ImageViewType.ByRoomName) inspectionId = item.room;
    else inspectionId = item.inspection;

    if (!groupedData[inspectionId]) {
      let sectionTitle: string = "";
      if (imageViewType === ImageViewType.ByRoomName && propertyRoomsData) {
        sectionTitle =
          propertyRoomsData.find((room) => room._id === item.room)?.name || "";
      } else if (
        imageViewType === ImageViewType.ByDate &&
        propertyInspectionsData
      ) {
        sectionTitle = item.createdAt
          ? new Date(item.createdAt).toLocaleDateString()
          : "";
      }

      groupedData[inspectionId] = {
        title: sectionTitle, // Use createdAt as the section title
        data: [item], // Create a new array with the formattedItem
        imageViewType: imageViewType,
      };
    } else {
      // Find the existing section and add the formattedItem to its imgs array
      const existingSection = groupedData[inspectionId];
      existingSection.data.push(item);
    }
  });

  // Convert the grouped data into an array of SectionData
  const formattedData: InspectionImagesGroupedData[] =
    Object.values(groupedData);

  store.dispatch({
    type: "UPDATE_INSPECTION_IMAGES",
    inspectionImagesData: convertToImageDocumentArray(formattedData),
  });
  if (setInspectionImagesGrouped) setInspectionImagesGrouped(formattedData);
};
