import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { selectLCPropertiesData, selectUsersData } from "../../ts/selectors";
import { useSelector } from "react-redux";
import { PropertyDocument, UserDocument } from "../../constants/types";
import { formatAddress } from "../properties/common/PropertyDetailsHeader";

// Key mapping for human-readable names
type BreadcrumbNameMap = {
  [key: string]: string;
};

const breadcrumbNameMap: BreadcrumbNameMap = {
  p: "Properties",
  mb: "Manage Borrowers",
  ml: "Manage Lenders",
  insp: "Property Draw",
  uu: "Update User",
  ap: "Add Property",
};

// List of breadcrumb segments to hide
const hideSegments = ["0", "1", "gpc"];

// see comments in getBreadcrumbName for explanation
const hideSegmentsIf = [["p", "mb"]];

const getBreadcrumbName = (
  pathname: string,
  usersData: UserDocument[] | null,
  lcPropertiesData: PropertyDocument[] | null
) => {
  // Default paths that don't need to be mapped to a property or user name
  const defaultPaths = [
    "gpc",
    "p",
    "ml",
    "mb",
    "newuser",
    "addProperty",
    "updateuser",
    "insp",
    "img",
  ];
  if (defaultPaths.includes(pathname)) {
    return null;
  }

  if (usersData && usersData.length > 0) {
    const user = usersData.find((user) => user._id === pathname);
    if (user) {
      const name =
        `${user.firstName || ""} ${user.lastName || ""}`.trim() || user.email;
      return name;
    }
  }
  if (lcPropertiesData && lcPropertiesData.length > 0) {
    const propertyData = lcPropertiesData.find(
      (property) => property._id === pathname
    );
    if (propertyData) {
      return formatAddress(propertyData?.address, [
        "streetLine1",
        "streetLine2",
        "city",
      ]);
    }
  }

  return null;
};

export default function CustomBreadcrumbs() {
  const location = useLocation();
  const usersData = useSelector(selectUsersData);
  const lcPropertiesData = useSelector(selectLCPropertiesData);
  // Split the pathname into segments and filter out the empty segments and the segment 'gpc'
  const pathnames = location.pathname.split("/").filter((x) => x);
  const breadcrumbs = pathnames
    .map((value, index) => {
      const last = index === pathnames.length - 1;
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;

      // Decide to render or hide the current breadcrumb segment
      if (hideSegments.includes(value)) {
        // Skip rendering this breadcrumb but still construct the full path in `to`
        return null; // Return null to skip rendering this particular breadcrumb
      }

      // if hideSegments[i][1 or great] is present then hideSegments[i][0] should be hidden, but if only hideSegments[i][0] is present then it should be shown
      // this is to hide properties under borrowers and lenders
      const currentHideSegmentIf = hideSegmentsIf.find(
        (segment) => segment[0] === value
      );
      if (currentHideSegmentIf) {
        const hideSegment = pathnames.includes(currentHideSegmentIf[1]);
        if (hideSegment) {
          return null;
        }
      }

      // Use the breadcrumb name mapping if available, otherwise use the raw value
      let breadcrumbName = breadcrumbNameMap[value] || value;
      breadcrumbName =
        getBreadcrumbName(value, usersData, lcPropertiesData) ?? breadcrumbName;

      return last ? (
        <Typography key={to} color="text.primary">
          {breadcrumbName}
        </Typography>
      ) : (
        <Link
          component={RouterLink}
          to={to}
          key={to}
          underline="hover"
          color="inherit"
        >
          {breadcrumbName}
        </Link>
      );
    })
    .filter(Boolean); // Filter out null values to avoid rendering empty slots

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ mb: 2, zIndex: 2, position: "relative" }} // Styling to ensure breadcrumbs are on top
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
}
