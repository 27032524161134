import React, { useEffect, useState } from "react";

import { Box, IconButton, Modal } from "@mui/material";
import {
  ArrowBack,
  ArrowBackIosNew,
  ArrowForwardIos,
  DeleteOutline,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { RightSidebarDetails } from "../../components/InspectionImages/RightSidebarDetails";
import { CustomImageV2 } from "../../components/common/Images/CustomImageV2";
import { useSelector } from "react-redux";
import {
  selectInspectionImagesData,
  selectUsersData,
} from "../../ts/selectors";
import { InspectionImageDocument, UserDocument } from "../../constants/types";
import { getUsers } from "../../services/apiHelper/users";
import { loadInspectionImages } from "../../components/InspectionImages/dataHelper";
import { removeInspectionImage } from "../../services/apiHelper/inspections";
import ConfirmDialog from "../../components/common/confirmDialog";

type ImageBigWithPropertyDetailsProps = {};

const WINDOW_WIDTH = window.innerWidth;
const WINDOW_HEIGHT = window.innerHeight;
const isPortrait = WINDOW_HEIGHT > WINDOW_WIDTH;
const IMAGE_H_W = isPortrait ? WINDOW_WIDTH * 0.9 : WINDOW_HEIGHT * 0.9;

const ImageBigWithPropertyDetails: React.FC<
  ImageBigWithPropertyDetailsProps
> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const usersData = useSelector(selectUsersData);
  const inspectionImagesData = useSelector(selectInspectionImagesData);
  const [selectedImage, setSelectedImage] =
    React.useState<InspectionImageDocument | null>(null);
  const [createdByUser, setCreatedByUser] = React.useState<UserDocument | null>(
    null
  );
  const params = new URLSearchParams(location.search);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const roomId = params.get("roomId");
  const inspId = params.get("inspId");
  const img = params.get("img");

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      handleArrowClick(
        "left",
        event as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>
      );
    } else if (event.key === "ArrowRight") {
      handleArrowClick(
        "right",
        event as unknown as React.MouseEvent<HTMLButtonElement, MouseEvent>
      );
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    if (!usersData) getUsers();
  }, [usersData]);

  useEffect(() => {
    if (!inspectionImagesData) {
      if (roomId) {
        loadInspectionImages({ roomId });
      } else if (inspId) {
        loadInspectionImages({ inspId });
      }
    }
  }, [roomId, inspId, inspectionImagesData]);

  useEffect(() => {
    if (inspectionImagesData && img) {
      const item = inspectionImagesData.find((item) => item.image._id === img);
      setSelectedImage(item || null);
    }
  }, [img, inspectionImagesData]);

  useEffect(() => {
    if (usersData && selectedImage) {
      const user = usersData.find(
        (user) => user._id === selectedImage.image.createdBy
      );
      setCreatedByUser(user || null);
    }
  }, [selectedImage, usersData]);

  const closeModal = () => {
    const roomOrInsp = roomId ? "roomId" : "inspId";

    const roomOrInspId = roomId ? roomId : inspId;

    const fullPath = location.pathname; // we are using full path instead of base path
    // because we also want to keep the path params
    const updatedPath = fullPath.replace("/img", "");
    const newPath = `${updatedPath}?${roomOrInsp}=${roomOrInspId}`;
    navigate(newPath);
  };

  const handleDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const deleteImage = async () => {
    // TODO fix delete image from backend
    if (selectedImage?._id) {
      removeInspectionImage(selectedImage._id)
        .then((res) => {})
        .finally(() => {
          closeModal();
        });
    }
    handleCloseConfirmDialog();
  };

  const navigateToImage = (imageId: string) => {
    const roomOrInsp = roomId ? "roomId" : "inspId";

    const roomOrInspId = roomId ? roomId : inspId;

    const fullPath = location.pathname; // we are using full path instead of base path
    // because we also want to keep the path params
    const newPath = `${fullPath}?${roomOrInsp}=${roomOrInspId}&img=${imageId}`;

    navigate(newPath);
  };

  const handleArrowClick = (
    direction: "left" | "right",
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (inspectionImagesData && img) {
      const imageIndex = inspectionImagesData.findIndex(
        (image) => image.image._id === img
      );

      if (imageIndex !== -1) {
        const nextImage =
          direction === "left"
            ? inspectionImagesData[imageIndex - 1]
            : inspectionImagesData[imageIndex + 1];

        if (nextImage) {
          navigateToImage(nextImage.image._id);
        }
      }
    }
  };

  const isArrowVisible = (direction: "left" | "right") => {
    if (!inspectionImagesData || !img) return false;
    const imageIndex = inspectionImagesData.findIndex(
      (item) => item.image._id === img
    );
    if (direction === "left") return imageIndex > 0;
    if (direction === "right")
      return imageIndex < inspectionImagesData.length - 1;
    return true;
  };

  const RenderImage = () => (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <IconButton
        onClick={closeModal}
        sx={{
          position: "absolute",
          top: 8,
          left: 16,
          paddingX: "20px",
          paddingY: "20px",
        }}
      >
        <ArrowBack fontSize="large" />
      </IconButton>
      {isArrowVisible("left") && (
        <IconButton
          onClick={(event) => handleArrowClick("left", event)}
          sx={{
            position: "absolute",
            left: 16,
            paddingX: "60px",
            paddingY: "75px",
          }}
        >
          <ArrowBackIosNew fontSize="large" />
        </IconButton>
      )}
      <CustomImageV2
        image={selectedImage?.image.imagePath || ""}
        imageStyle={{
          width: "auto",
          height: "auto",
          maxWidth: IMAGE_H_W,
          maxHeight: IMAGE_H_W,
        }}
        shouldPlay={true}
      />
      {isArrowVisible("right") && (
        <IconButton
          onClick={(event) => handleArrowClick("right", event)}
          sx={{
            position: "absolute",
            right: 16,
            paddingX: "60px",
            paddingY: "75px",
          }}
        >
          <ArrowForwardIos fontSize="large" />
        </IconButton>
      )}
      <IconButton
        onClick={handleDeleteClick}
        sx={{
          position: "absolute",
          top: 8,
          right: 16,
          paddingX: "20px",
          paddingY: "20px",
        }}
      >
        <DeleteOutline fontSize="large" />
      </IconButton>
    </Box>
  );
  return (
    <Modal
      open={true}
      aria-labelledby="zoomed-image"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        overflow: "auto",
        position: "fixed",
        top: 0,
        left: 0,
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          cursor: "pointer",
          width: "100%",
          height: "100%",
        }}
        onClick={closeModal}
      >
        <RenderImage />

        <RightSidebarDetails
          createdByUser={createdByUser}
          selectedImage={selectedImage}
        />
        <ConfirmDialog
          open={openConfirmDialog}
          dialogText={{
            title: "Confirm Deletion",
            description: "Are you sure you want to delete this image?",
            cancel: "Cancel",
            confirm: "Confirm",
          }}
          onClose={handleCloseConfirmDialog}
          onConfirmAction={deleteImage}
        />
      </Box>
    </Modal>
  );
};

export default ImageBigWithPropertyDetails;
