import { getPreSignedUrl } from "../services/apiHelper/images";
import JSZip from "jszip";

type DownloadImagesAsZipProps = {
  imagePaths: string[];
  fileName?: string;
};

export const cacheOrRequestMediaFromBackend = async (
  mediaPath: string,
  fileName?: string // Optional file name parameter
): Promise<string | null> => {
  try {
    const cacheName = "pikcheck-media-cache";
    const cache = await caches.open(cacheName);
    const storeFileName = mediaPath.replace(/\//g, "");
    const cacheKey = new Request(storeFileName);

    // Check if the media response is in the cache
    const cachedResponse = await cache.match(cacheKey);
    if (cachedResponse) {
      // Use the cached Blob to create a local URL
      const blob = await cachedResponse.blob();
      const url = URL.createObjectURL(blob);

      if (fileName) {
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName; // Rename the file
        document.body.appendChild(link);
        link.click();

        // Clean up
        URL.revokeObjectURL(url);
        document.body.removeChild(link);

        return null; // Return null because the file has been downloaded
      }

      return url; // Return the Blob URL if no file name is provided
    } else {
      // Get the pre-signed URL
      const signedUrl = await getPreSignedUrl(mediaPath);
      if (signedUrl) {
        console.log("Caching media for future use");
        // Fetch the media and add it to the cache
        const response = await fetch(signedUrl);
        if (response.ok) {
          const blob = await response.blob();
          await cache.put(cacheKey, new Response(blob));
          const url = URL.createObjectURL(blob);

          if (fileName) {
            const link = document.createElement("a");
            link.href = url;
            link.download = fileName; // Rename the file
            document.body.appendChild(link);
            link.click();

            // Clean up
            URL.revokeObjectURL(url);
            document.body.removeChild(link);

            return null; // Return null because the file has been downloaded
          }

          return url; // Return the Blob URL if no file name is provided
        }
      }
      return null;
    }
  } catch (error) {
    console.error("Error caching or requesting the media:", error);
    return null;
  }
};

export const downloadImagesAsZip = async ({
  imagePaths,
  fileName = "images.zip",
}: DownloadImagesAsZipProps): Promise<void> => {
  const zip = new JSZip();
  let counter = 1;

  for (const path of imagePaths) {
    const imageURL: string | null = await cacheOrRequestMediaFromBackend(path);
    if (imageURL) {
      const response: Response = await fetch(imageURL);
      const blob: Blob = await response.blob();
      const fileExtension = path.split(".").pop() || "bin"; // Extract the extension or use 'bin' as a fallback
      const formattedFileName = `image${String(counter).padStart(
        4,
        "0"
      )}.${fileExtension}`;
      zip.file(formattedFileName, blob);
      counter++;
    }
  }

  zip
    .generateAsync({ type: "blob" })
    .then((content: Blob) => {
      const url = URL.createObjectURL(content);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    })
    .catch((error: any) => {
      console.error("Error generating zip file:", error);
    });
};

export const getFilenameFromUri = (uri: string | null | undefined) => {
  if (!uri) return "";

  return uri.substring(uri.lastIndexOf("/") + 1);
};

export const getMimeType = (fileName: string) => {
  const parts = fileName.split(".");
  const extension = parts.length > 1 ? parts.pop()!.toLowerCase() : "";
  switch (extension) {
    case "mp4":
      return "video/mp4";
    case "mov":
      return "video/quicktime";
    case "avi":
      return "video/avi";
    case "mpeg":
      return "video/mpeg";
    // Add other formats as needed
    default:
      return "application/octet-stream"; // Generic file type if unknown
  }
};
