import { useLocation } from "react-router-dom";

export function useBasePath() {
  // The purpose of this function is to help with backlinks for ml and mb routes when viewing properties.
  // It constructs the base path for the current route based on the route identifiers in the URL.
  // Maybe clean this up in future.

  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  // Look for known route identifiers ('p' or 'mb' or 'ml') and construct the base path accordingly
  let basePath = "/gpc";

  if (pathSegments.includes("mb")) {
    // Construct base path for 'mb' routes
    const userIdIndex = pathSegments.indexOf("mb") + 1;
    const userId = pathSegments[userIdIndex];

    basePath += `/mb/${userId}`;
  } else if (pathSegments.includes("ml")) {
    // Construct base path for 'ml' routes
    const userIdIndex = pathSegments.indexOf("ml") + 1;
    const userId = pathSegments[userIdIndex];

    basePath += `/ml/${userId}`;
  }

  if (pathSegments.includes("p")) {
    // Simple base path for 'p' routes
    basePath += "/p";
  }
  return basePath;
}
