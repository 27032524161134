import React from "react";
import { Button, TableCell } from "@mui/material";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from "@mui/icons-material";

type SelectRowButtonProps = {
  value: string;
  selectedId: string | null;
  setSelectedId: (value: string) => void;
  buttonType?: "radio" | "checkbox";
  indeterminate?: boolean | null;
};

export const SelectRowButton: React.FC<SelectRowButtonProps> = ({
  value,
  selectedId,
  setSelectedId,
  buttonType = "radio",
  indeterminate = false,
}) => {
  const isSelected = value === selectedId;

  const handleClick = () => {
    setSelectedId(value);
  };

  const getIcon = () => {
    if (buttonType === "radio") {
      return isSelected ? (
        <RadioButtonChecked color="secondary" />
      ) : (
        <RadioButtonUnchecked color="secondary" />
      );
    } else if (buttonType === "checkbox") {
      if (indeterminate) {
        return <IndeterminateCheckBox color="secondary" />;
      }
      return isSelected ? (
        <CheckBox color="secondary" />
      ) : (
        <CheckBoxOutlineBlank color="secondary" />
      );
    }
  };

  return (
    <TableCell align="left">
      <Button startIcon={getIcon()} onClick={handleClick}></Button>
    </TableCell>
  );
};
