import axios, { AxiosResponse, AxiosError } from "axios";
import { Auth } from "aws-amplify";
import { BACKEND_HOST, BASEPATHS } from "../config";

interface ApiRequestParams {
  path: string;
  data?: Record<string, any>;
  dataType?: string;
  headers?: Record<string, string>;
}

export default class ApiService {
  private baseUrl: string;
  private getToken: boolean;

  constructor(getToken: boolean = true, baseUrl: string = BACKEND_HOST) {
    this.baseUrl = BACKEND_HOST;
    // this.baseUrl = "http://localhost:80";
    this.getToken = getToken;
  }

  private async addAuthTokenToHeaders(): Promise<null> {
    if (!this.getToken) return null;
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.accessToken.jwtToken;

      axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
      return null;
    } catch (error) {
      console.error("Error fetching auth token:", error);
      return null;
    }
  }

  private getFullPath(path: string, dataType?: string): string {
    const rootPath = dataType ? BASEPATHS[dataType] : "";
    return `${this.baseUrl}/${rootPath}${path}`;
  }

  private async handleResponse<T>(
    responsePromise: Promise<AxiosResponse<T>>
  ): Promise<T> {
    try {
      const response = await responsePromise;
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      // Handle common error scenarios
      if (axiosError.response) {
        switch (axiosError.response.status) {
          case 401:
            // Handle unauthorized error
            break;
          case 403:
            // Handle forbidden error
            break;
          // Add more cases as necessary
          default:
            // Handle other errors
            break;
        }
      }
      // Rethrow the error for specific handling where the API is called
      throw axiosError;
    }
  }

  public async post<T>(params: ApiRequestParams): Promise<T> {
    const fullPath = this.getFullPath(params.path, params.dataType);
    await this.addAuthTokenToHeaders();

    return this.handleResponse(
      axios.post<T>(fullPath, params.data, { headers: params.headers })
    );
  }

  public async patch<T>(params: ApiRequestParams): Promise<T> {
    const fullPath = this.getFullPath(params.path, params.dataType);
    await this.addAuthTokenToHeaders();

    return this.handleResponse(
      axios.patch<T>(fullPath, params.data, { headers: params.headers })
    );
  }

  public async get<T>(params: ApiRequestParams): Promise<T> {
    const fullPath = this.getFullPath(params.path, params.dataType);
    await this.addAuthTokenToHeaders();

    return this.handleResponse(
      axios.get<T>(fullPath, { params: params.data, headers: params.headers })
    );
  }

  public async delete<T>(params: ApiRequestParams): Promise<T> {
    const fullPath = this.getFullPath(params.path, params.dataType);
    await this.addAuthTokenToHeaders();

    return this.handleResponse(
      axios.delete<T>(fullPath, { data: params.data, headers: params.headers })
    );
  }
}
