import { Box, Link, Typography } from "@mui/material";
import {
  InspectionImageDocument,
  LatLng,
  UserDocument,
} from "../../constants/types";
import {
  AccountCircleOutlined,
  LocationOnOutlined,
  TodayOutlined,
} from "@mui/icons-material";

type RightSidebarDetailsProps = {
  createdByUser: UserDocument | null;
  selectedImage: InspectionImageDocument | null;
};

export const RightSidebarDetails: React.FC<RightSidebarDetailsProps> = ({
  createdByUser,
  selectedImage,
}) => {
  const createGoogleMapsLink = (location: LatLng) => {
    return `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "1.3rem",
        backgroundColor: "background.paper",
        minWidth: "450px",
        px: 4,
      }}
    >
      {selectedImage?.image.location && (
        <Link
          href={createGoogleMapsLink(selectedImage.image.location)}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            color: "text.primary",
          }}
        >
          <LocationOnOutlined fontSize="large" />
          <Typography variant="h6">
            Location: {selectedImage.image.location.lat.toFixed(5)},{" "}
            {selectedImage.image.location.lng.toFixed(5)}
          </Typography>
        </Link>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          fontSize: "1.3rem",
          my: 5,
        }}
      >
        <TodayOutlined fontSize="large" />
        <Typography variant="h6">
          Uploaded on:{" "}
          {selectedImage?.createdAt
            ? new Date(selectedImage.createdAt).toLocaleDateString()
            : ""}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          fontSize: "1.3rem",
          my: 2,
        }}
      >
        <AccountCircleOutlined fontSize="large" />
        <Typography variant="h6">
          Uploaded By:{" "}
          {createdByUser
            ? `${createdByUser.firstName || ""} ${
                createdByUser.lastName || ""
              }`.trim() || createdByUser.email
            : "Unknown User"}
        </Typography>
      </Box>
    </Box>
  );
};
