import React, { useEffect, useMemo } from "react";

import { useOutletContext, useParams } from "react-router-dom";
import { LayoutContextType } from "../../../constants/types";
import { Box } from "@mui/material";
import {
  DrawerHeader,
  Main,
} from "../../../components/properties/common/LayoutComponents";
import UpdateUserForm from "../../../components/users/UpdateUserForm";
import { useSelector } from "react-redux";
import { selectUsersData } from "../../../ts/selectors";
import { getUsers } from "../../../services/apiHelper/users";
import CustomBreadcrumbs from "../../../components/navbar/CustomBreadcrumbs";
interface UpdateUserProps {}

const UpdateUser: React.FC<UpdateUserProps> = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();

  const { userId: userIdParam } = useParams<{ userId?: string }>();
  const userId = userIdParam ?? "0";

  const usersData = useSelector(selectUsersData);

  useEffect(() => {
    if (!usersData) {
      getUsers();
    }
  }, [usersData]);

  const filteredUser = useMemo(() => {
    return usersData?.find((user) => user._id === userId) ?? null;
  }, [usersData, userId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
        }}
      >
        <Main open={open} drawerWidth={drawerWidth}>
          <DrawerHeader />
          <CustomBreadcrumbs />

          <UpdateUserForm userData={filteredUser} />
        </Main>
      </Box>
    </>
  );
};

export default UpdateUser;
