import {
  InspectionDocument,
  InspectionImageDocument,
  PropertyDocument,
  RoomDocument,
  UserDocument,
} from "../../constants/types";
import {
  SIGN_OUT,
  UPDATE_LC_PROPERTIES,
  UPDATE_USER_PROPERTIES,
  UPDATE_PROPERTY_INSPECTIONS,
  UPDATE_PROPERTY_ROOMS,
  UPDATE_INSPECTION_IMAGES,
  UPDATE_LC_USERS,
  UPDATE_USER_DATA,
  UPDATE_NOTIFICATION,
  ADD_USER_NOTIFICATIONS,
  UPDATE_READ_NOTIFICATION,
} from "../constants/action-types";

// Define the types for your actions and state
interface UserNotification {
  _id: string;
  createdAt: string;
  read: boolean;
}

interface HelpWindows {
  [key: string]: boolean;
}

export interface RootState {
  userData: UserDocument | null;
  usersData: UserDocument[] | null;
  userPropertiesData: PropertyDocument[] | null;
  propertyInspectionsData: InspectionDocument[] | null;
  propertyRoomsData: RoomDocument[] | null;
  inspectionImagesData: InspectionImageDocument[] | null;
  lcPropertiesData: PropertyDocument[] | null;
  helpWindows: HelpWindows;
  notificationUpdate: any; // Define the structure or replace 'any' with appropriate type
  userNotifications: UserNotification[];
}

interface Action {
  type: string;
  userData?: UserDocument;
  usersData?: UserDocument[];
  userPropertiesData?: PropertyDocument[];
  propertyInspectionsData?: InspectionDocument[];
  propertyRoomsData?: RoomDocument[];
  inspectionImagesData?: InspectionImageDocument[];
  lcPropertiesData?: PropertyDocument[];
  notificationUpdate?: any; // Define the structure or replace 'any' with appropriate type
  newNotifications?: UserNotification[];
  notificationId?: string;
}

const initialState: RootState = {
  userData: null,
  usersData: null,
  userPropertiesData: null,
  propertyInspectionsData: null,
  propertyRoomsData: null,
  inspectionImagesData: null,
  lcPropertiesData: null,
  helpWindows: {
    childStats_1: true,
    welcomePage: true,
  },
  notificationUpdate: null,
  userNotifications: [],
};

function rootReducer(
  prevState: RootState = initialState,
  action: Action
): RootState {
  switch (action.type) {
    case SIGN_OUT:
      return initialState;
    case UPDATE_LC_PROPERTIES:
      return {
        ...prevState,
        lcPropertiesData: action.lcPropertiesData ?? null,
      };
    case UPDATE_USER_PROPERTIES:
      return {
        ...prevState,
        userPropertiesData: action.userPropertiesData ?? null,
      };
    case UPDATE_PROPERTY_INSPECTIONS:
      return {
        ...prevState,
        propertyInspectionsData: action.propertyInspectionsData ?? null,
      };
    case UPDATE_PROPERTY_ROOMS:
      return {
        ...prevState,
        propertyRoomsData: action.propertyRoomsData ?? null,
      };
    case UPDATE_INSPECTION_IMAGES:
      return {
        ...prevState,
        inspectionImagesData: action.inspectionImagesData ?? null,
      };
    case UPDATE_LC_USERS:
      return {
        ...prevState,
        usersData: action.usersData ?? null,
      };
    case UPDATE_USER_DATA:
      return {
        ...prevState,
        userData: action.userData ?? null,
      };
    case UPDATE_NOTIFICATION:
      return {
        ...prevState,
        notificationUpdate: action.notificationUpdate,
      };

    case ADD_USER_NOTIFICATIONS:
      const newNotifications = action.newNotifications
        ? [...action.newNotifications, ...prevState.userNotifications]
        : prevState.userNotifications;
      newNotifications.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
      return {
        ...prevState,
        userNotifications: newNotifications,
      };
    case UPDATE_READ_NOTIFICATION:
      const updatedNotifications = prevState.userNotifications.map(
        (notification) =>
          notification._id === action.notificationId
            ? { ...notification, read: true }
            : notification
      );
      return {
        ...prevState,
        userNotifications: updatedNotifications,
      };
    default:
      return prevState;
  }
}

export default rootReducer;
