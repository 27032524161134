import React, { useEffect, useMemo, useState } from "react";

import { useOutletContext, useParams } from "react-router-dom";
import { LayoutContextType } from "../../../constants/types";
import { Box } from "@mui/material";
import {
  DrawerHeader,
  Main,
} from "../../../components/properties/common/LayoutComponents";
import AddPropertyForm from "../../../components/users/AddPropertyForm";
import { selectUsersData } from "../../../ts/selectors";
import { useSelector } from "react-redux";
import { GenericHeaderTitleDataProps } from "../../../components/common/dashboard/GenericHeader";
import { getUsers } from "../../../services/apiHelper/users";
import CustomBreadcrumbs from "../../../components/navbar/CustomBreadcrumbs";
import { USER_TYPES_STRING } from "../../../config";

interface AddNewPropertyProps {
  // You can define props here if needed. For this simple example, it's empty.
}

const AddNewProperty: React.FC<AddNewPropertyProps> = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();

  const { userId: userIdParam } = useParams<{ userId?: string }>();
  const userId = userIdParam ?? "0";

  const usersData = useSelector(selectUsersData);
  const [headerTitleData, setHeaderTitleData] = useState<
    GenericHeaderTitleDataProps | undefined
  >({ title: "", subtitle: "" });

  const filteredUser = useMemo(() => {
    return usersData?.find((user) => user._id === userId) ?? null;
  }, [usersData, userId]);

  useEffect(() => {
    if (!usersData) {
      getUsers();
    }
  }, [usersData]);

  useEffect(() => {
    if (filteredUser) {
      setHeaderTitleData({
        title: filteredUser
          ? `${filteredUser.firstName || ""} ${
              filteredUser.lastName || ""
            }`.trim()
          : "Unknown User",
        subtitle: USER_TYPES_STRING[filteredUser.userType],
      });
    }
  }, [filteredUser]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
        }}
      >
        <Main open={open} drawerWidth={drawerWidth}>
          <DrawerHeader />
          <CustomBreadcrumbs />

          <AddPropertyForm selectedUserData={headerTitleData} />
        </Main>
      </Box>
    </>
  );
};

export default AddNewProperty;
