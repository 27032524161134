import React, { useEffect, useMemo } from "react";
import { DashboardHeadCell, PropertyDocument } from "../../constants/types";
import { useSelector } from "react-redux";
import { selectLCPropertiesData } from "../../ts/selectors";
import { Box } from "@mui/material";
import { SelectRowButton } from "../common/dashboard/DashCustomCols/SelectRowButton";
import DashboardTable from "../common/dashboard/DashboardTable";
import { Save } from "@mui/icons-material";
import { getLCProperties } from "../../services/apiHelper/properties";
import { AddressCol } from "../common/dashboard/DashCustomCols/AddressCol";

type PropertySelectorTableProps = {
  multiSelect?: boolean;
  currentUserId?: string;
  selectedIds: string[] | null;
  setSelectedIds: (value: string[]) => void;
  handleHeaderButtonClick?: () => void;
};

const PropertySelectorTable: React.FC<PropertySelectorTableProps> = ({
  multiSelect = false,
  currentUserId,
  selectedIds,
  setSelectedIds,
  handleHeaderButtonClick,
}) => {
  const [dataRows, setDataRows] = React.useState<PropertyDocument[] | null>(
    null
  );

  const lcPropertiesData = useSelector(selectLCPropertiesData);

  const headCells: DashboardHeadCell<PropertyDocument>[] = [
    {
      id: "_id",
      columnKey: "_idButton",
      align: "left",
      isSearchable: false,
      disablePadding: false,
      label: "Selected User",
      // customHeadCell to select/deselect all
      customHeadCell: () => {
        const isAllSelected =
          dataRows && selectedIds && dataRows.length === selectedIds.length;
        const isPartiallySelected = selectedIds && selectedIds.length > 0;
        const toggleSelectAll = () => {
          if (isAllSelected) {
            setSelectedIds([]);
          } else {
            const allIds = dataRows?.map((row) => row._id) || [];
            setSelectedIds(allIds);
          }
        };

        return (
          <SelectRowButton
            value="select-all"
            selectedId={isAllSelected ? "select-all" : null}
            setSelectedId={toggleSelectAll}
            buttonType="checkbox"
            indeterminate={isPartiallySelected && !isAllSelected}
          />
        );
      },
      customCell: (id) => {
        const selectedId = selectedIds?.includes(id) ? id : null;
        const setSelectedId = (id: string) => {
          if (selectedIds?.includes(id)) {
            setSelectedIds(
              selectedIds.filter((selectedId) => selectedId !== id)
            );
          } else {
            setSelectedIds(selectedIds ? [...selectedIds, id] : [id]);
          }
        };

        return (
          <SelectRowButton
            value={id}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            buttonType={multiSelect ? "checkbox" : "radio"}
          />
        );
      },
    },
    {
      id: "address",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "Address",
      customCell: (address) => <AddressCol addressVal={address} />,
    },
    {
      id: "notes",
      align: "center",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: false,
      label: "Notes",
    },
  ];

  const filteredProperties = useMemo(() => {
    return (
      lcPropertiesData?.filter(
        (property) =>
          !currentUserId || !property.userList.includes(currentUserId)
      ) ?? null
    );
  }, [currentUserId, lcPropertiesData]);

  useEffect(() => {
    if (!lcPropertiesData) {
      getLCProperties();
    } else {
      if (filteredProperties) setDataRows(filteredProperties);
    }
  }, [filteredProperties, lcPropertiesData]);

  const handleOnRowClick = (_id: string) => {
    if (multiSelect) {
      setSelectedIds(
        selectedIds?.includes(_id)
          ? selectedIds.filter((selectedId) => selectedId !== _id)
          : [...(selectedIds || []), _id]
      );
    } else {
      setSelectedIds(selectedIds?.includes(_id) ? [] : [_id]);
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          padding: "16px",

          backgroundColor: "background.paper",
          borderRadius: "15px",
        }}
      >
        <DashboardTable
          dataRows={dataRows}
          headCells={headCells}
          fuseThreshold={0.4}
          headerTitleData={{
            title: "Select properties to add",
            subtitle: "",
          }}
          headerButtonData={
            handleHeaderButtonClick && {
              label: `Save Propert${
                selectedIds && selectedIds?.length > 1 ? "ies" : "y"
              }`,
              startIcon: <Save />,
              disabled: !selectedIds?.length,
              handleClick: handleHeaderButtonClick,
            }
          }
          handleOnRowClick={handleOnRowClick}
          maxTableHeight="400px"
          noRowsMessage={{
            title: "No properties yet",
            subtitle:
              "Add a property in the Property Management section. Contact support if you need assistance.",
          }}
          minTableHeight="175px"
        />
      </Box>
    </>
  );
};

export default PropertySelectorTable;
