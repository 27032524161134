import ApiService from "../api";
import { BASEPATHS } from "../../config";
import store from "../../ts/store";

interface Add_Inspection_Image_API_CALL {
  formData: FormData;
  roomId: string;
  inspectionId: string;
}

interface Update_Inspection_API_CALL {
  inspectionId: string;
  notes?: String;
}

export const createNewInspection = async (
  propertyId: string,
  inspectionDate: Date
) => {
  const apiService = new ApiService();
  try {
    const inspectionData = await apiService.post<any>({
      path: "/createNewInspection",
      dataType: BASEPATHS.inspection,
      data: {
        property: propertyId,
        date: inspectionDate,
      },
    });

    return inspectionData;
  } catch (error) {
    console.error("Error creating new inspection:", error);
    throw error;
  }
};

export const addInspectionImage = async ({
  formData,
  inspectionId,
  roomId,
}: Add_Inspection_Image_API_CALL) => {
  const apiService = new ApiService();
  try {
    const addImageRes = await apiService.post<any>({
      path: `/addInspectionImage?roomId=${roomId}&inspectionId=${inspectionId}`,
      dataType: BASEPATHS.inspection,
      data: formData,

      headers: { "Content-Type": "multipart/form-data" },
    });

    return addImageRes;
  } catch (error) {
    console.error("Error adding new inspection image:", error);
    throw error;
  }
};

export const updateInspection = async ({
  inspectionId,
  notes,
}: Update_Inspection_API_CALL) => {
  const apiService = new ApiService();
  try {
    const updatedInspection = await apiService.post<any>({
      path: "/updateInspection",
      dataType: BASEPATHS.inspection,
      data: {
        _id: inspectionId,
        notes: notes,
        // completed: true,
      },
    });

    await getPropertyInspections(updatedInspection.inspection.property);

    return updatedInspection;
  } catch (error) {
    console.error("Error updating inspection :", error);
    throw error;
  }
};

export const getPropertyInspections = async (propertyId: string) => {
  const apiService = new ApiService();

  try {
    const inspectionsData = await apiService.get<any>({
      path: "/getInspections",
      dataType: BASEPATHS.inspection,
      data: {
        propertyId: propertyId,
      },
    });
    store.dispatch({
      type: "UPDATE_PROPERTY_INSPECTIONS",
      propertyInspectionsData: inspectionsData.inspections,
    });

    return inspectionsData;
  } catch (error) {
    console.error("Error getting User inspections:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const getInspectionImages = async ({
  inspectionId,
  roomId,
}: {
  inspectionId?: string;
  roomId?: string;
}) => {
  const apiService = new ApiService();

  try {
    const inspectionImagesData = await apiService.get<any>({
      path: `/getInspectionImages`,
      dataType: BASEPATHS.inspection,
      data: {
        inspectionId,
        roomId,
      },
    });

    return inspectionImagesData.inspectionImages;
  } catch (error) {
    console.error("Error getting Inspection images:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};

export const removeInspection = async (inspectionId: string) => {
  const apiService = new ApiService();
  try {
    const removedInspection = await apiService.post<any>({
      path: "/removeInspection",
      dataType: BASEPATHS.inspection,
      data: {
        inspectionId,
      },
    });

    return removedInspection;
  } catch (error) {
    console.error("Error removing inspection  :", error);
    throw error;
  }
};

export const removeInspectionImage = async (imageId: string) => {
  const apiService = new ApiService();
  try {
    const updatedInspection = await apiService.post<any>({
      path: "/removeInspectionImage",
      dataType: BASEPATHS.inspection,
      data: {
        iiId: imageId,
      },
    });

    return updatedInspection;
  } catch (error) {
    console.error("Error removing inspection image :", error);
    throw error;
  }
};
