import React from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import {
  DashboardHeadCell,
  HeaderButtonDataProps,
} from "../../../constants/types";

export interface GenericHeaderTitleDataProps {
  title: string;
  subtitle: string;
}

interface HeaderProps<DataRowType> {
  headerTitleData?: GenericHeaderTitleDataProps;
  headCells?: DashboardHeadCell<DataRowType>[];

  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  selectedSearchColumn?: string;
  setSelectedSearchColumn?: React.Dispatch<React.SetStateAction<string>>;
  headerButtonData?: HeaderButtonDataProps;
}

const searchFieldStyles = {
  borderRadius: "8px",
  width: "100%",
  // transition: "width 0.2s ease",
  // "&:focus-within": {
  //   width: "100%",
  // },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "8px",
    },
  },
};

const inputPropsStyles = {
  backgroundColor: "background.paper",
  borderRadius: "4px",
  width: "100%",
};

const GenericHeader = <DataRowType extends { _id: string }>({
  searchTerm,
  setSearchTerm,
  selectedSearchColumn,
  setSelectedSearchColumn,
  headCells,
  headerButtonData,
  headerTitleData,
}: HeaderProps<DataRowType>) => {
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm && setSearchTerm(e.target.value);
  };

  const handleColumnChange = (event: SelectChangeEvent) => {
    setSelectedSearchColumn &&
      setSelectedSearchColumn(event.target.value as string);
  };

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      xs={12}
    >
      {headerTitleData && (
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Typography variant="h5" sx={{ color: "text.primary" }}>
            {headerTitleData.title}{" "}
          </Typography>
          <Typography variant="h6" sx={{ color: "text.secondary" }}>
            {headerTitleData.subtitle}
          </Typography>
        </Grid>
      )}
      {searchTerm !== undefined && setSearchTerm && !!headCells && (
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            id="outlined-search"
            placeholder="Search"
            type="search"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={searchFieldStyles}
            InputProps={{
              sx: inputPropsStyles,
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "text.secondary" }} />
                </InputAdornment>
              ),
              endAdornment: selectedSearchColumn ? (
                <InputAdornment position="end">
                  <FormControl variant="standard" sx={{ minWidth: 120 }}>
                    <Select
                      value={selectedSearchColumn}
                      onChange={handleColumnChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{ color: "text.secondary" }}
                    >
                      <MenuItem value="all">Filter</MenuItem>
                      {headCells.map((headCell) => {
                        return (
                          headCell.isSearchable && (
                            <MenuItem
                              key={headCell.id as string}
                              value={headCell.id as string}
                            >
                              {headCell.label}
                            </MenuItem>
                          )
                        );
                      })}
                    </Select>
                  </FormControl>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
      )}

      {headerButtonData && (
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Box sx={{ textAlign: "right" }}>
            <Button
              startIcon={headerButtonData.startIcon}
              variant="contained"
              color={headerButtonData.color}
              size="large"
              disabled={headerButtonData.disabled}
              sx={{
                " & .MuiButton-startIcon": {
                  marginRight: 4,
                },
              }}
              onClick={headerButtonData.handleClick}
              disableRipple
            >
              {headerButtonData.label}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default GenericHeader;
