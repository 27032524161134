import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { UserDocument } from "../../constants/types";
import {
  UpdateUser_API_CALL,
  UserType_API_CALL,
  updateLCUser,
} from "../../services/apiHelper/users";
import CountdownTimer from "../common/CountdownTimer";
import { useNavigate } from "react-router";

interface UpdateUserFormProps {
  userData: UserDocument | null;
}

const UpdateUserForm: React.FC<UpdateUserFormProps> = ({ userData }) => {
  const [user, setUser] = useState<UpdateUser_API_CALL>({
    _id: userData?._id || "",
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    email: userData?.email || "",
    notes: userData?.notes || "",
    borrowingEntity: userData?.borrowingEntity || "",
    loanProID: userData?.loanProID || "",
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null); // State to store the error message

  useEffect(() => {
    if (userData) {
      setUser({
        _id: userData._id,
        firstName: userData.firstName ?? "",
        lastName: userData.lastName ?? "",
        email: userData.email ?? "",
        notes: userData.notes ?? "",
        borrowingEntity: userData.borrowingEntity ?? "",
        loanProID: userData.loanProID ?? "",
      });
      setIsSubmitting(false);
      setIsSuccess(false);
    }
  }, [userData]);

  const handleChange =
    (prop: keyof UserType_API_CALL) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUser({ ...user, [prop]: event.target.value });
    };

  const handleGoBack = () => {
    navigate(`/gpc/mb/${userData?._id}`);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null); // Reset error message before the API call
    setIsSubmitting(true);
    setIsSuccess(false);

    try {
      await updateLCUser(user);
      setIsSuccess(true);
    } catch (error: any) {
      setError(
        error?.response?.data?.error ||
          "An unexpected error occurred. Reach out to support for assistance."
      ); // Set error message
    } finally {
      setIsSubmitting(false); // Reset the isSubmitting state
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Update User Info
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={6}
            columnSpacing={2.75}
            justifyContent="space-between"
          >
            {/* User Info Inputs */}
            <Grid container item rowSpacing={1} columnSpacing={2.75} xs={12}>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <TextField
                  label="Enter First Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={user.firstName}
                  onChange={handleChange("firstName")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={4}>
                <TextField
                  label="Enter Last Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={user.lastName}
                  onChange={handleChange("lastName")}
                />
              </Grid>
            </Grid>
            <Grid container item rowSpacing={2} columnSpacing={2.75} xs={12}>
              <Grid item xs={12} sm={7} md={6} lg={5}>
                <TextField
                  label="Enter Email"
                  variant="outlined"
                  fullWidth
                  required
                  value={user.email}
                  onChange={handleChange("email")}
                />
              </Grid>
            </Grid>

            {userData?.userType === 1 && (
              <>
                <Grid
                  container
                  item
                  rowSpacing={1}
                  columnSpacing={2.75}
                  xs={12}
                >
                  <Grid item xs={12} sm={5} md={4.5} lg={4}>
                    <TextField
                      label="LoanPro ID"
                      variant="outlined"
                      value={user.loanProID}
                      onChange={handleChange("loanProID")}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  rowSpacing={1}
                  columnSpacing={2.75}
                  xs={12}
                >
                  <Grid item xs={12} sm={5} md={4.5} lg={4}>
                    <TextField
                      label="Borrowing Entity"
                      variant="outlined"
                      value={user.borrowingEntity}
                      onChange={handleChange("borrowingEntity")}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {/* Save Button */}
            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 10,
                  padding: 1,
                  textAlign: "center",
                  borderRadius: 3,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disableRipple
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
                {/* Error Alert */}
                {isSuccess && (
                  <>
                    <Alert severity="success" sx={{ mt: 2 }}>
                      User info updated successfully!
                    </Alert>
                    <CountdownTimer
                      duration={4}
                      onComplete={handleGoBack} // Close the dialog when countdown finishes
                    />
                  </>
                )}
                {error && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                  </Alert>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default UpdateUserForm;
