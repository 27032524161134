import React from "react";
import { PaletteColor, TableCell, Typography, useTheme } from "@mui/material";

const hexToRGBA = (hex: string, opacity: number) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const CheckInStatus: React.FC<{ status: number }> = ({ status }) => {
  let color: PaletteColor;
  let title;
  const theme = useTheme();
  switch (Number(status)) {
    case 0:
      color = theme.palette.warning;
      title = "Draft Started";
      break;
    case 1:
      color = theme.palette.success;
      title = "Submitted";
      break;
    case 2:
      color = theme.palette.error;
      title = "Past Due";
      break;
    default:
      color = theme.palette.info;
      title = "N/A";
  }
  const hexColor = color.main;
  const backgroundColor: string =
    theme.palette.mode === "dark"
      ? hexToRGBA(hexColor, 0.8)
      : hexToRGBA(hexColor, 1);
  return (
    <TableCell
      align="center"
      sx={{
        backgroundColor: backgroundColor,

        color: "background.contrastText",
      }}
    >
      <Typography>{title}</Typography>
    </TableCell>
  );
};
