import React, { useEffect, useMemo, useState } from "react";
import {
  DashboardHeadCell,
  HeaderButtonDataProps,
  UserDocument,
} from "../../constants/types";
import { useSelector } from "react-redux";
import { selectUsersData } from "../../ts/selectors";
import { Alert, Box, DialogContentText } from "@mui/material";
import { USER_TYPES, USER_TYPES_STRING } from "../../config";
import { NumberOfProperties } from "../common/dashboard/DashCustomCols/NumberOfProperties";
import { getUsers } from "../../services/apiHelper/users";
import DashboardTable from "../common/dashboard/DashboardTable";
import { CustomString } from "../common/dashboard/DashCustomCols/CustomString";
import {
  addUserToProperty,
  getLCProperties,
  removeBorrowerFromProperty,
} from "../../services/apiHelper/properties";
import { PersonAdd, PersonAddDisabled } from "@mui/icons-material";
import UserSelectorTable from "./UserSelectorTable";
import GenericHeader from "../common/dashboard/GenericHeader";
import { RemoveItem } from "../common/dashboard/DashCustomCols/RemoveItem";
import { CustomDialog } from "../common/CustomDialog";

type UsersInPropertyTableProps = {
  userList: string[];
  propertyId: string;
};

const UsersInPropertyTable: React.FC<UsersInPropertyTableProps> = ({
  userList,
  propertyId,
}) => {
  const [dataRows, setDataRows] = useState<UserDocument[] | null>(null);
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[] | null>(null);
  const [error, setError] = useState<string | null>(null); // State to store the error message
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [pluralString, setPluralString] = useState<string>("s");
  const [userToRemove, setUserToRemove] = useState<UserDocument | null>(null);

  const usersData = useSelector(selectUsersData);

  const handleRemoveUserFromProperty = async () => {
    try {
      if (!userToRemove) return;

      const updatedProperty = await removeBorrowerFromProperty(
        userToRemove._id,
        propertyId
      );
      await Promise.all([
        getUsers(), // Refresh the  users in property
        getLCProperties(), // Refresh the properties data. This updates redux
      ]);
      return updatedProperty;
    } catch (error) {
      throw error;
    }
  };

  const headCells: DashboardHeadCell<UserDocument>[] = [
    {
      id: "firstName",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "First Name",
    },
    {
      id: "lastName",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Last Name",
    },
    {
      id: "email",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "propertyList",
      align: "left",
      isSearchable: false,
      disablePadding: true,
      label: "# of Properties",
      customCell: (propertyList) => (
        <NumberOfProperties propertyList={propertyList} />
      ),
    },

    {
      id: "loanProID",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "Loan Pro ID",
    },
    {
      id: "userType",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Type",
      customCell: (userType) => {
        const userTypeString = USER_TYPES_STRING[userType]?.toString();

        return <CustomString value={userTypeString} />;
      },
    },
    {
      id: "_id",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "",
      customCell: (userId) => {
        const user = usersData?.find((user) => user._id === userId);
        const isUserLender = user?.userType === USER_TYPES.LENDER;

        return (
          <RemoveItem
            buttonDisabled={isUserLender}
            handleDeleteClick={() => setUserToRemove(user ?? null)}
          />
        );
      },
    },
  ];

  const filteredUser = useMemo(() => {
    return usersData?.filter((user) => userList.includes(user._id)) ?? null;
  }, [userList, usersData]);

  useEffect(() => {
    if (!usersData) {
      getUsers();
    } else {
      if (filteredUser) setDataRows(filteredUser);
    }
  }, [filteredUser, usersData]);

  const handleOnRowClick = (_id: string) => {};

  const handleHeaderButtonClick = () => {
    setIsAddingUser(!isAddingUser);
  };

  const handleAddUsersToProperty = async () => {
    setError(null);
    setIsSubmitting(true);
    setIsSuccess(false);
    try {
      if (!selectedIds) {
        setError("Please select users");
        setIsSubmitting(false);
        return;
      }

      setPluralString(selectedIds.length > 1 ? "s" : "");

      for (const selectedId of selectedIds) {
        await addUserToProperty(propertyId, selectedId);
      }

      await Promise.all([
        getUsers(), // Refresh the  users in property
        getLCProperties(), // Refresh the properties data. This updates redux
      ]);
      setIsSuccess(true);
      setIsAddingUser(false);

      setSelectedIds(null);
    } catch (error: any) {
      setError(
        error?.response?.data?.error ||
          "An unexpected error occurred. Reach out to support for assistance."
      ); // Set error message
      setIsSubmitting(false);
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseConfirmDialog = () => {
    setUserToRemove(null);
  };

  const headerButtonData: HeaderButtonDataProps = {
    label: isAddingUser ? "Close Add Users" : "Add Users",
    startIcon: isAddingUser ? <PersonAddDisabled /> : <PersonAdd />,
    handleClick: handleHeaderButtonClick,
    color: isAddingUser ? "warning" : "primary",
  };

  return (
    <>
      <CustomDialog
        isOpen={!!userToRemove}
        title="Confirm Removal"
        content={{
          initial: (
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to remove{" "}
              {userToRemove?.firstName && userToRemove?.lastName ? (
                <strong>{`${userToRemove.firstName} ${userToRemove.lastName}`}</strong>
              ) : (
                userToRemove?.username || ""
              )}{" "}
              from this property?
            </DialogContentText>
          ),
          successMessage: "User Removed successfully",
        }}
        submitButton={{
          regular: "Remove",
          isSubmitting: "Removing...",
        }}
        handleCloseDialog={handleCloseConfirmDialog}
        handleClick={handleRemoveUserFromProperty}
      />

      <Box mb={2}>
        <GenericHeader
          headerTitleData={{
            title: "Property Users",
            subtitle: "",
          }}
          headCells={headCells}
          headerButtonData={headerButtonData}
        />
      </Box>
      {error && (
        <Alert
          onClose={() => {
            setError(null);
          }}
          severity="error"
          sx={{ my: 2 }}
        >
          {error}
        </Alert>
      )}
      {isSubmitting && (
        <Alert
          onClose={() => {
            setIsSubmitting(false);
          }}
          severity="info"
          sx={{ my: 2 }}
        >
          Adding user{pluralString} to property...
        </Alert>
      )}
      {isSuccess && (
        <Alert
          onClose={() => {
            setIsSuccess(false);
          }}
          severity="success"
          sx={{ my: 2 }}
        >
          User{pluralString} added successfully
        </Alert>
      )}

      {isAddingUser && (
        <UserSelectorTable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          multiSelect={true}
          currentPropertyId={propertyId}
          handleHeaderButtonClick={handleAddUsersToProperty}
        />
      )}

      <Box
        sx={{
          maxWidth: "100%",
          padding: "16px",
          borderRadius: "15px",
        }}
      >
        <DashboardTable
          dataRows={dataRows}
          headCells={headCells}
          fuseThreshold={0.4}
          handleOnRowClick={handleOnRowClick}
          maxTableHeight="400px"
          minTableHeight="175px"
          noRowsMessage={{
            title: "No users in property",
            subtitle: "Click 'Add Users' to get started",
          }}
        />
      </Box>
    </>
  );
};

export default UsersInPropertyTable;
