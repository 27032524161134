import React from "react";

import { useOutletContext } from "react-router-dom";
import { LayoutContextType } from "../../../constants/types";
import { Box } from "@mui/material";
import {
  DrawerHeader,
  Main,
} from "../../../components/properties/common/LayoutComponents";
import CreateUserForm from "../../../components/users/CreateUserForm";
interface CreateNewUserProps {
  // You can define props here if needed. For this simple example, it's empty.
}

const CreateNewUser: React.FC<CreateNewUserProps> = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
        }}
      >
        <Main open={open} drawerWidth={drawerWidth}>
          <DrawerHeader />
          <CreateUserForm />
        </Main>
      </Box>
    </>
  );
};

export default CreateNewUser;
