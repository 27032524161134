import { Box, DialogContentText } from "@mui/material";

import DashboardTable from "../common/dashboard/DashboardTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DashboardHeadCell, InspectionDocument } from "../../constants/types";
import { InspectionAlerts } from "../common/dashboard/DashCustomCols/InspectionAlerts";
import { CheckInStatus } from "../common/dashboard/DashCustomCols/CheckInStatus";
import { CustomString } from "../common/dashboard/DashCustomCols/CustomString";
import moment from "moment";
import { RemoveItem } from "../common/dashboard/DashCustomCols/RemoveItem";
import { CustomDialog } from "../common/CustomDialog";
import { useState } from "react";
import {
  getPropertyInspections,
  removeInspection,
} from "../../services/apiHelper/inspections";

interface InspectionListProps {
  propertyInspectionsData: InspectionDocument[] | null;
}

const InspectionList: React.FC<InspectionListProps> = ({
  propertyInspectionsData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { propertyId: propertyIdParam } = useParams<{
    propertyId?: string;
  }>();

  const [inspectionToRemove, setInspectionToRemove] =
    useState<InspectionDocument | null>(null);

  const headCells: DashboardHeadCell<InspectionDocument>[] = [
    {
      id: "date",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "Date Created",
      customCell: (value: Date) => {
        const inspectionDate = moment(value);
        const dateFormatted = inspectionDate.format("MMMM D, YYYY");

        return <CustomString value={dateFormatted} />;
      },
    },

    {
      id: "forceOverride",
      align: "center",
      headerAlign: "center",
      isSearchable: false,
      disablePadding: false,
      label: "Alerts",
      customCell: (value) => <InspectionAlerts alertType={value} />,
    },

    {
      id: "status",
      align: "left",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: false,
      label: "Status",
      customCell: (value) => <CheckInStatus status={value} />,
    },
    {
      id: "drawAmount",
      align: "center",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: false,
      label: "Amount",
    },
    {
      id: "description",
      columnKey: "notes2",
      align: "center",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: false,
      label: "Description",
    },
    {
      id: "_id",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "",
      customCell: (inspectionId) => {
        const inspection = propertyInspectionsData?.find(
          (inspection) => inspection._id === inspectionId
        );

        return (
          <RemoveItem
            handleDeleteClick={() => setInspectionToRemove(inspection ?? null)}
          />
        );
      },
    },
  ];

  const handleRemoveInspection = async () => {
    try {
      if (!inspectionToRemove) return;

      const removedInspection = await removeInspection(inspectionToRemove._id);
      await getPropertyInspections(propertyIdParam || "");
      return removedInspection;
    } catch (error) {
      throw error;
    }
  };

  const handleCloseConfirmDialog = () => {
    setInspectionToRemove(null);
  };

  const RenderDialogContent = () => {
    return (
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to remove the draw{" "}
        {inspectionToRemove?.date ? (
          <strong>
            {moment(inspectionToRemove.date).format("MMMM D, YYYY")}
          </strong>
        ) : (
          ""
        )}{" "}
        from this property?
      </DialogContentText>
    );
  };

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <CustomDialog
        isOpen={!!inspectionToRemove}
        title="Confirm Removal"
        content={{
          initial: <RenderDialogContent />,
          successMessage: "Draw Removed successfully",
        }}
        submitButton={{
          regular: "Remove",
          isSubmitting: "Removing...",
        }}
        handleCloseDialog={handleCloseConfirmDialog}
        handleClick={handleRemoveInspection}
      />
      <DashboardTable
        dataRows={propertyInspectionsData ?? null}
        headCells={headCells}
        fuseThreshold={0.4}
        handleOnRowClick={(id: string) => {
          const fullPath = location.pathname; // we are using full path instead of base path
          // because we also want to keep the path params
          const newPath = `${fullPath}/insp?inspId=${id}`;
          navigate(newPath);
        }}
        minTableHeight="200px"
        noRowsMessage={{
          title: "No inspection yet",
          subtitle: "Recent inspections will be displayed here once added",
        }}
      />
    </Box>
  );
};

export default InspectionList;
