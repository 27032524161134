import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type ConfirmDialogProps = {
  open: boolean;
  dialogText: {
    title: string;
    description: string;
    cancel: string;
    confirm: string;
  };
  onClose: () => void;
  onConfirmAction: () => void;
};

const ConfirmDialog = ({
  open,
  dialogText,
  onClose,
  onConfirmAction,
}: ConfirmDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    onClick={(event) => event.stopPropagation()} // Stops the click event from propagating
  >
    <DialogTitle id="alert-dialog-title">{dialogText.title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {dialogText.description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={(event) => {
          onClose();
          event.stopPropagation();
        }}
        variant="contained"
        color="primary"
      >
        {dialogText.cancel}
      </Button>
      <Button
        onClick={(event) => {
          onConfirmAction();
          event.stopPropagation();
        }}
        variant="contained"
        color="primary"
        autoFocus
      >
        {dialogText.confirm}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
