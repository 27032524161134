import React, { useEffect, useMemo } from "react";
import { DashboardHeadCell, UserDocument } from "../../constants/types";
import { useSelector } from "react-redux";
import { selectUsersData } from "../../ts/selectors";
import { Box } from "@mui/material";
import { SelectRowButton } from "../common/dashboard/DashCustomCols/SelectRowButton";
import { USER_TYPES_STRING } from "../../config";
import { NumberOfProperties } from "../common/dashboard/DashCustomCols/NumberOfProperties";
import { getUsers } from "../../services/apiHelper/users";
import DashboardTable from "../common/dashboard/DashboardTable";
import { Save } from "@mui/icons-material";
import { CustomString } from "../common/dashboard/DashCustomCols/CustomString";

type UserSelectorTableProps = {
  multiSelect?: boolean;
  currentPropertyId?: string;
  selectedIds: string[] | null;
  setSelectedIds: (value: string[]) => void;
  handleHeaderButtonClick?: () => void;
};

const UserSelectorTable: React.FC<UserSelectorTableProps> = ({
  multiSelect = false,
  currentPropertyId,
  selectedIds,
  setSelectedIds,
  handleHeaderButtonClick,
}) => {
  const [dataRows, setDataRows] = React.useState<UserDocument[] | null>(null);

  const usersData = useSelector(selectUsersData);

  const headCells: DashboardHeadCell<UserDocument>[] = [
    {
      id: "_id",
      columnKey: "_idButton",
      align: "left",
      isSearchable: false,
      disablePadding: false,
      label: "Selected User",
      customHeadCell: () => {
        const isAllSelected =
          dataRows && selectedIds && dataRows.length === selectedIds.length;
        const isPartiallySelected = selectedIds && selectedIds.length > 0;
        const toggleSelectAll = () => {
          if (isAllSelected) {
            setSelectedIds([]);
          } else {
            const allIds = dataRows?.map((row) => row._id) || [];
            setSelectedIds(allIds);
          }
        };

        return (
          <SelectRowButton
            value="select-all"
            selectedId={isAllSelected ? "select-all" : null}
            setSelectedId={toggleSelectAll}
            buttonType="checkbox"
            indeterminate={isPartiallySelected && !isAllSelected}
          />
        );
      },
      customCell: (id) => {
        const selectedId = selectedIds?.includes(id) ? id : null;
        const setSelectedId = (id: string) => {
          if (selectedIds?.includes(id)) {
            setSelectedIds(
              selectedIds.filter((selectedId) => selectedId !== id)
            );
          } else {
            setSelectedIds(selectedIds ? [...selectedIds, id] : [id]);
          }
        };

        return (
          <SelectRowButton
            value={id}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            buttonType={multiSelect ? "checkbox" : "radio"}
          />
        );
      },
    },
    {
      id: "firstName",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "First Name",
    },
    {
      id: "lastName",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Last Name",
    },
    {
      id: "email",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "propertyList",
      align: "left",
      isSearchable: false,
      disablePadding: true,
      label: "# of Properties",
      customCell: (propertyList) => (
        <NumberOfProperties propertyList={propertyList} />
      ),
    },
    {
      id: "userType",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Type",
      customCell: (userType) => {
        const userTypeString = USER_TYPES_STRING[userType]?.toString();

        return <CustomString value={userTypeString} />;
      },
    },
  ];

  const filteredUser = useMemo(() => {
    return (
      usersData?.filter(
        (user) =>
          !currentPropertyId || !user.propertyList.includes(currentPropertyId)
      ) ?? null
    );
  }, [currentPropertyId, usersData]);

  useEffect(() => {
    if (!usersData) {
      getUsers();
    } else {
      if (filteredUser) setDataRows(filteredUser);
    }
  }, [filteredUser, usersData]);

  const handleOnRowClick = (_id: string) => {
    if (multiSelect) {
      setSelectedIds(
        selectedIds?.includes(_id)
          ? selectedIds.filter((selectedId) => selectedId !== _id)
          : [...(selectedIds || []), _id]
      );
    } else {
      setSelectedIds(selectedIds?.includes(_id) ? [] : [_id]);
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          padding: "16px",

          backgroundColor: "background.paper",
          borderRadius: "15px",
        }}
      >
        <DashboardTable
          dataRows={dataRows}
          headCells={headCells}
          fuseThreshold={0.4}
          headerTitleData={
            handleHeaderButtonClick
              ? undefined
              : {
                  title: "Select a user for the property",
                  subtitle: "",
                }
          }
          headerButtonData={
            handleHeaderButtonClick && {
              label: `Save User${
                selectedIds && selectedIds?.length > 1 ? "s" : ""
              }`,
              startIcon: <Save />,
              disabled: !selectedIds?.length,
              handleClick: handleHeaderButtonClick,
            }
          }
          handleOnRowClick={handleOnRowClick}
          maxTableHeight="400px"
          noRowsMessage={{
            title: "No users yet",
            subtitle:
              "Add a user in the User Management section. Contact support if you need assistance.",
          }}
          minTableHeight="175px"
        />
      </Box>
    </>
  );
};

export default UserSelectorTable;
