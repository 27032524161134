import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { ImageDocument, InspectionImageDocument } from "../../constants/types";
import { CustomImageV2 } from "../common/Images/CustomImageV2";
import { Download } from "@mui/icons-material";
import { downloadImagesAsZip } from "../../utils/imageUtils";

const MAX_IMAGE_HEIGHT = 200;

type ImageGroupWithHeaderProps = {
  imgBatchDescription: string;
  imageBatch: InspectionImageDocument[];
  handleOpen: (imgSrc: ImageDocument) => void;
};

export const ImageGroupWithHeader: React.FC<ImageGroupWithHeaderProps> = ({
  imgBatchDescription,
  imageBatch,
  handleOpen,
}) => {
  const [cols, setCols] = useState(3);

  useEffect(() => {
    // Adjusts the number of cols based on window width
    const updateCols = () => {
      if (window.innerWidth < 300) {
        setCols(2);
      } else if (window.innerWidth < 600) {
        setCols(3);
      } else if (window.innerWidth < 900) {
        setCols(5);
      } else if (window.innerWidth < 1200) {
        setCols(6);
      } else {
        setCols(7);
      }
    };

    updateCols();
    window.addEventListener("resize", updateCols);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", updateCols);
  }, []);

  const handleDownloadClick = () => {
    const imagePaths = imageBatch.map((item) => item.image.imagePath);
    downloadImagesAsZip({
      imagePaths: imagePaths,
      fileName: `Inspection_Export_${imgBatchDescription}`,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ mr: 8 }}>
          {imgBatchDescription}
        </Typography>
        <Button
          startIcon={<Download />}
          variant="outlined"
          size="large"
          sx={{
            " & .MuiButton-startIcon": {
              marginRight: 4,
            },
          }}
          onClick={handleDownloadClick}
          disableRipple
        >
          Download Images
        </Button>
      </Box>
      <ImageList cols={cols} gap={8} sx={{ marginBottom: 5 }}>
        {imageBatch.map((item, index) => (
          <ButtonBase
            onClick={() => handleOpen(item.image)}
            disableRipple
            sx={{
              padding: 0,
              margin: 0,
              display: "block",
            }}
            key={index}
          >
            <ImageListItem key={item._id}>
              <CustomImageV2
                image={item.image.imagePath}
                imageStyle={{
                  width: "100%",
                  height: MAX_IMAGE_HEIGHT,
                  objectFit: "cover",
                }}
                shouldPlay={true}
              />
            </ImageListItem>
          </ButtonBase>
        ))}
      </ImageList>
    </>
  );
};
