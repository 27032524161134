import { Box, Typography, Button, BoxProps } from "@mui/material";

interface NotesDisplayProps extends BoxProps {
  openSidebar?: () => void;
  notes?: string;
  alwaysShowTitle?: boolean;
  title: string;
  buttonTitle?: string;
}

const NotesDisplay: React.FC<NotesDisplayProps> = ({
  openSidebar,
  notes,
  alwaysShowTitle,
  title,
  buttonTitle,

  ...boxProps
}) => {
  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "background.paper",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      {...boxProps}
    >
      <Box>
        <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
          {(notes || alwaysShowTitle) && `${title}:`}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {notes}
        </Typography>
      </Box>
      <Button variant="contained" size="medium" onClick={openSidebar}>
        {buttonTitle ?? "Notes"}
      </Button>
    </Box>
  );
};

export default NotesDisplay;
