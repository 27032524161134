export const SIGN_OUT: string = "SIGN_OUT";
export const UPDATE_LC_PROPERTIES: string = "UPDATE_LC_PROPERTIES";
export const UPDATE_USER_PROPERTIES: string = "UPDATE_USER_PROPERTIES";
export const UPDATE_PROPERTY_INSPECTIONS: string =
  "UPDATE_PROPERTY_INSPECTIONS";
export const UPDATE_PROPERTY_ROOMS: string = "UPDATE_PROPERTY_ROOMS";
export const UPDATE_INSPECTION_IMAGES: string = "UPDATE_INSPECTION_IMAGES";
export const UPDATE_LC_USERS: string = "UPDATE_LC_USERS";
export const UPDATE_USER_DATA: string = "UPDATE_USER_DATA";
export const UPDATE_NOTIFICATION: string = "UPDATE_NOTIFICATION";
export const ADD_USER_NOTIFICATIONS: string = "ADD_USER_NOTIFICATIONS";
export const UPDATE_READ_NOTIFICATION: string = "UPDATE_READ_NOTIFICATION";
