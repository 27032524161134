export type RootParamList = {
  HomeScreen: undefined;
  PagesWithTabs: undefined;
  ProfileScreen: undefined;
  TermsConditions: undefined;
  PropertyInspectionsScreen: {
    property: PropertyDocument;
  };
  CheckInScreen: {
    property: PropertyDocument;
    inspection: InspectionDocument;
  };
  SubmitInspectionScreen: {
    inspectionId: string;
    property: PropertyDocument;
  };
  EditProfileModal: undefined;
  ViewInspectionScreen: {
    property: PropertyDocument;
    inspections: InspectionDocument;
    room: RoomDocument;
  };
  ViewNotificationScreen: undefined;
};

enum STATUS_ENUM {
  // late: 0, submitted: 1, approved: 2, rejected: 3, returned: 4
  LATE,
  SUBMITTED,
  APPROVED,
  REJECTED,
}

export enum ImageViewType {
  ByDate,
  ByRoomName,
}

export enum ViewType {
  LIST,
  GRID,
}

export type LayoutContextType = {
  open: boolean;
  drawerWidth: number;
};

export type Property = {
  _id: number;
  name: string;
  borrowerName: string;
  date: string;
  distance: number;
  address: string;
  isLastRow: boolean;
  status: STATUS_ENUM;
};

export interface HeaderButtonDataProps {
  label: string;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  startIcon?: React.ReactNode;
  disabled?: boolean;

  handleClick: () => void;
}

export interface GoogleMapsApiPlaceResult {
  formatted_address: string;
  address_components: Address;
  geometry: {
    location: LatLng;
    viewport: Viewport;
  };
}

export interface PropertyDataWithMapAPI {
  id: number;
  address: GoogleMapsApiPlaceResult | null;
  loanEndDate?: Date | null;
  loanAmount?: number | null;
}

export interface DashboardHeadCell<DataRowType> {
  id: keyof DataRowType;
  columnKey?: string;
  align: "left" | "right" | "center";
  headerAlign?: "flex-start" | "flex-end" | "center";
  isSearchable?: boolean;
  disablePadding: boolean;
  label: string;
  customHeadCell?: (value: any) => React.ReactNode;
  customCell?: (value: any, _id?: string) => React.ReactNode;
}

export interface UserDocument {
  _id: string;
  username: string;
  createdAt: Date;
  email: string;
  propertyList: string[];

  firstName?: string;
  lastName?: string;
  profilePic: string;
  borrowingEntity?: string;
  loanProID?: string;
  notes?: string;
  userType: number;
  phoneNumber?: string;
  lendingCompany?: string;
  devicePlatform?: string;
  deviceToken?: string;
  updatedAt: Date;
  lastLogin?: Date;
}
export interface Address {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state: string;
  zip: string;
}
export interface LatLng {
  lat: number;
  lng: number;
}

export interface Viewport {
  low: LatLng;
  high: LatLng;
}

export interface PropertyDocument {
  _id: string;
  address: Address;
  location: LatLng;
  rooms: string[];
  loanAllocation: number;
  loanEndDate: Date;
  viewport: Viewport;
  notes?: string;
  lendingCompany?: string;
  userList: string[];
  scopeOfWork?: Attachment;
  createdAt: Date;
  updatedAt: Date;
  alertType?: number; // todo add this to backend
  status?: number; // todo add this to backend
}

export type Attachment = {
  _id: string;
  path: string;
  name: string;
};

export interface InspectionDocument {
  _id: string;
  date: Date;
  room: string;
  property: string;
  reasonForSuspiciousEntry?: string;
  forceOverride?: number;
  notes?: string;
  drawAmount?: number;
  description?: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  isLastRow?: boolean;
}

export interface RoomDocument {
  _id: string;
  name: string;
  property?: string;
  unit?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface InspectionImageDocument {
  _id: string;
  room: string;
  inspection: string;
  image: ImageDocument;
  createdAt: Date;
  updatedAt: Date;
}

export interface ImageDocument {
  _id: string;
  createdBy: string;
  tags: string[];
  imagePath: string;
  imageType: string;
  location?: LatLng;
  deviceType?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface NotificationDocument {
  _id: string;
  recipient: string;
  intendedFor: string;
  sender: string;
  data: string;
  read: boolean;
  // Notification type 1 is for new draw
  type: number;
}

export interface RoomInspectionsData {
  room: RoomDocument;
  inspectionImages: InspectionImageDocument[];
}
