// todo clean up and improve this file

import React, { useEffect } from "react";

import { useLocation, useOutletContext, useParams } from "react-router-dom";
import {
  ImageViewType,
  InspectionDocument,
  InspectionImageDocument,
  LayoutContextType,
  PropertyDocument,
} from "../../constants/types";
import { Box, Grid } from "@mui/material";
import PropertyDetailsHeader from "../../components/properties/common/PropertyDetailsHeader";
import InspectionsPhotoLibrary from "../../components/properties/InspectionsPhotoLibrary";
import {
  Main,
  DrawerHeader,
} from "../../components/properties/common/LayoutComponents";
import {
  getLCProperties,
  getPropertyRooms,
} from "../../services/apiHelper/properties";
import { useSelector } from "react-redux";
import {
  selectLCPropertiesData,
  selectPropertyInspectionsData,
  selectPropertyRoomsData,
} from "../../ts/selectors";
import { getPropertyInspections } from "../../services/apiHelper/inspections";
import { loadInspectionImages } from "../../components/InspectionImages/dataHelper";
import CustomBreadcrumbs from "../../components/navbar/CustomBreadcrumbs";

type InspectionImagesProps = {};

export type InspectionImagesGroupedData = {
  title: string;
  data: InspectionImageDocument[];
  imageViewType: ImageViewType;
};

const InspectionImages: React.FC<InspectionImagesProps> = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();
  const propertyRoomsData = useSelector(selectPropertyRoomsData);
  const propertyInspectionsData = useSelector(selectPropertyInspectionsData);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { propertyId: propertyIdParam } = useParams<{
    propertyId?: string;
  }>();

  const roomId = params.get("roomId");
  const inspId = params.get("inspId");

  const imageViewType: ImageViewType = roomId
    ? ImageViewType.ByDate
    : ImageViewType.ByRoomName;

  const lcPropertiesData = useSelector(selectLCPropertiesData);
  const [propertyData, setPropertyData] =
    React.useState<PropertyDocument | null>(null);
  const [inspectionImagesGrouped, setInspectionImagesGrouped] = React.useState<
    InspectionImagesGroupedData[]
  >([]);
  const [headerTitle, setHeaderTitle] = React.useState<string>("");
  const [inspecitonData, setInspectionData] =
    React.useState<InspectionDocument | null>(null);

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (propertyIdParam) {
        const fetchTasks = [];

        if (!propertyRoomsData)
          fetchTasks.push(getPropertyRooms(propertyIdParam));

        if (!propertyInspectionsData)
          fetchTasks.push(getPropertyInspections(propertyIdParam));
        if (fetchTasks.length > 0) await Promise.all(fetchTasks);
      }
    };

    fetchPropertyData();
  }, [propertyIdParam, propertyRoomsData, propertyInspectionsData]);

  useEffect(() => {
    if (lcPropertiesData == null) {
      getLCProperties();
    }

    if (lcPropertiesData && propertyIdParam) {
      const property = lcPropertiesData.find(
        (property) => property._id === propertyIdParam
      );
      if (property) setPropertyData(property);
    }

    if (roomId && propertyRoomsData) {
      const room = propertyRoomsData.find((room) => room._id === roomId);
      if (room) {
        setHeaderTitle(room.name);
      }
    }

    if (propertyIdParam && (roomId || inspId)) {
      if (roomId)
        loadInspectionImages({
          roomId,
          propertyInspectionsData,
          propertyRoomsData,
          setInspectionImagesGrouped,
        });
      else if (inspId)
        loadInspectionImages({
          inspId,
          propertyInspectionsData,
          propertyRoomsData,
          setInspectionImagesGrouped,
        });
    }

    if (inspId && propertyInspectionsData) {
      const inspection = propertyInspectionsData.find(
        (inspection) => inspection._id === inspId
      );
      if (inspection) {
        const newData = new Date(inspection.date);
        const formattedDate = newData.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
        setInspectionData(inspection);
        setHeaderTitle(formattedDate);
      }
    }
  }, [
    lcPropertiesData,
    propertyIdParam,
    roomId,
    inspId,
    propertyInspectionsData,
    propertyRoomsData,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      <Main open={open} drawerWidth={drawerWidth}>
        <DrawerHeader />
        <CustomBreadcrumbs />
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          {/* row 1 */}
          <PropertyDetailsHeader
            titleData={headerTitle || "Loading ..."}
            inspectionData={inspecitonData}
            imageViewType={imageViewType}
            propertyData={propertyData}
          />
          <Grid item xs={12}>
            <InspectionsPhotoLibrary
              inspectionImagesGroupedData={inspectionImagesGrouped}
              imageViewType={imageViewType}
              urlParams={{
                roomId_param: roomId,
                inspId_param: inspId,
              }}
            />
          </Grid>
        </Grid>
      </Main>
    </Box>
  );
};

export default InspectionImages;
