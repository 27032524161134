import ApiService from "../api";
import { BASEPATHS } from "../../config";

export const getPreSignedUrl = async (imagePath: string) => {
  const apiService = new ApiService();

  try {
    const imageData = await apiService.get<any>({
      path: "/getPreSignedUrl",
      dataType: BASEPATHS.general,
      data: { imagePath },
    });

    return imageData.message;
  } catch (error) {
    console.error("Error getting image:", error);
    throw error;
  }
};
