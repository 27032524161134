import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CountdownTimer from "./CountdownTimer"; // Import the countdown component

export const CustomDialog = ({
  isOpen,
  title,
  content,
  submitButton,
  error,
  handleCloseDialog,
  handleClick,
}: {
  isOpen: boolean;
  title: string;
  content: {
    initial: React.ReactNode;
    successMessage: string;
  };
  submitButton: {
    regular: string;
    isSubmitting: string;
  };
  error?: string | null;

  handleCloseDialog: () => void;
  handleClick: () => Promise<any>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    // reset values on close
    if (isOpen) {
      return;
    }
    setIsSubmitting(false);
    setIsSuccess(false);
  }, [isOpen]);

  return (
    <Dialog
      open={!!isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClick={(event) => event.stopPropagation()} // Stops the click event from propagating
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {isSuccess ? (
          <>
            <Typography variant="h6" style={{ marginTop: 10, minWidth: 400 }}>
              {content.successMessage}
            </Typography>
            <CountdownTimer
              duration={4}
              onComplete={handleCloseDialog} // Close the dialog when countdown finishes
            />
          </>
        ) : (
          content.initial
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(event) => {
            handleCloseDialog();
            event.stopPropagation();
          }}
          variant="contained"
          color="primary"
        >
          {isSuccess ? "Close" : "Cancel"}
        </Button>
        <Button
          onClick={async (event) => {
            setIsSubmitting(true);
            try {
              const res = await handleClick();

              if (res) {
                setIsSuccess(true);
              }
            } catch (e: any) {
              console.log(e);
            } finally {
              setIsSubmitting(false);
            }

            event.stopPropagation();
          }}
          variant="contained"
          color="primary"
          autoFocus
          style={{
            display: isSuccess ? "none" : "block",
            textAlign: "center",
          }}
        >
          {isSubmitting ? submitButton.isSubmitting : submitButton.regular}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
