// todo add types

import { RootState } from "../reducers";

export const selectLCPropertiesData = (state: RootState) =>
  state.lcPropertiesData;
export const selectUserPropertiesData = (state: RootState) =>
  state.userPropertiesData;
export const selectPropertyInspectionsData = (state: RootState) =>
  state.propertyInspectionsData;
export const selectPropertyRoomsData = (state: RootState) =>
  state.propertyRoomsData;
export const selectInspectionImagesData = (state: RootState) =>
  state.inspectionImagesData;
export const selectUsersData = (state: RootState) => state.usersData;

export const selectUserData = (state: RootState) => state.userData;

export const selectNotificationUpdate = (state: { notificationUpdate: any }) =>
  state.notificationUpdate;

export const selectUserNotifications = (state: { userNotifications: any }) =>
  state.userNotifications;
