import React from "react";
import { TableCell, Typography } from "@mui/material";

export const NumberOfProperties: React.FC<{ propertyList: string[] }> = ({
  propertyList,
}) => (
  <TableCell align="left">
    <Typography component="span" sx={{ color: "text.primary" }}>
      {propertyList.length ?? 0}
    </Typography>
  </TableCell>
);
