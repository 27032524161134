import React, { useState } from "react";
import { HeaderButtonDataProps } from "../../constants/types";
import { Alert, Box } from "@mui/material";
import { getUsers } from "../../services/apiHelper/users";
import {
  addUserToProperty,
  getLCProperties,
} from "../../services/apiHelper/properties";
import { DomainAdd, DomainDisabledOutlined } from "@mui/icons-material";
import GenericHeader from "../common/dashboard/GenericHeader";
import PropertySelectorTable from "./PropertySelectorTable";

type AddExistingPropertiesToUserProps = {
  headerTitleData: {
    title: string;
    subtitle: string;
  };
  userId: string;
};

const AddExistingPropertiesToUser: React.FC<
  AddExistingPropertiesToUserProps
> = ({ headerTitleData, userId }) => {
  const [isAddingProperty, setIsAddingProperty] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[] | null>(null);
  const [error, setError] = useState<string | null>(null); // State to store the error message
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [pluralString, setPluralString] = useState<string>("ies");

  const handleHeaderButtonClick = () => {
    setIsAddingProperty(!isAddingProperty);
  };

  const handlePropertiesToUser = async () => {
    setError(null);
    setIsSubmitting(true);
    setIsSuccess(false);
    try {
      if (!selectedIds) {
        setError("Please select properties");
        setIsSubmitting(false);
        return;
      }

      setPluralString(selectedIds.length > 1 ? "ies" : "y");

      for (const selectedId of selectedIds) {
        await addUserToProperty(selectedId, userId);
      }

      await Promise.all([
        getUsers(), // Refresh the  users in property
        getLCProperties(), // Refresh the properties data. This updates redux
      ]);
      setIsSuccess(true);
      setIsAddingProperty(false);

      setSelectedIds(null);
    } catch (error: any) {
      setError(
        error?.response?.data?.error ||
          "An unexpected error occurred. Reach out to support for assistance."
      ); // Set error message
      setIsSubmitting(false);
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const headerButtonData: HeaderButtonDataProps = {
    label: isAddingProperty ? "Close Properties" : "Add Existing",
    startIcon: isAddingProperty ? <DomainDisabledOutlined /> : <DomainAdd />,
    handleClick: handleHeaderButtonClick,
    color: isAddingProperty ? "warning" : "primary",
  };

  return (
    <Box mb={10}>
      <Box mb={2}>
        <GenericHeader
          headerTitleData={headerTitleData}
          headerButtonData={headerButtonData}
        />
      </Box>
      {error && (
        <Alert
          onClose={() => {
            setError(null);
          }}
          severity="error"
          sx={{ my: 2 }}
        >
          {error}
        </Alert>
      )}
      {isSubmitting && (
        <Alert
          onClose={() => {
            setIsSubmitting(false);
          }}
          severity="info"
          sx={{ my: 2 }}
        >
          Adding propert{pluralString} to user...
        </Alert>
      )}
      {isSuccess && (
        <Alert
          onClose={() => {
            setIsSuccess(false);
          }}
          severity="success"
          sx={{ my: 2 }}
        >
          Propert{pluralString} added successfully
        </Alert>
      )}

      {isAddingProperty && (
        <PropertySelectorTable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          multiSelect={true}
          currentUserId={userId}
          handleHeaderButtonClick={handlePropertiesToUser}
        />
      )}
    </Box>
  );
};

export default AddExistingPropertiesToUser;
