// theme.ts

import { createTheme } from "@mui/material/styles";
import { grey, green, yellow, blueGrey } from "@mui/material/colors";

// Define the light theme with green palette
const lightTheme = createTheme({
  palette: {
    mode: "light",
    contrastThreshold: 3,

    primary: {
      main: "#038960",
    },
    secondary: {
      main: "#09CC91",
      light: "#09CC914D",
      contrastText: "#037956",
    },
    background: {
      paper: "#fff",
      default: grey[50],
      contrastText: "#fff",
    },
    backgroundMoreColors: {
      paper: grey[100],
      default: grey[300],
    },
    text: {
      primary: "#000",
      secondary: "#888",
      disabled: grey[500],
    },
    textMoreColors: {
      primary: "#888",
      secondary: "#fff",
      tertiary: "#000",
    },
    imageViewTypeBox: {
      byDate: green[100],
      byRoomName: yellow[300],
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    contrastThreshold: 3,
    primary: {
      main: "#038960",
      contrastText: "white",
    },
    secondary: {
      main: "#09CC91",
      light: "#09CC914D",
    },
    background: {
      paper: blueGrey[900],
      default: grey[900],
      contrastText: "#fff",
    },
    backgroundMoreColors: {
      paper: grey[800],
      default: grey[600],
    },
    text: {
      primary: grey[300],
      secondary: grey[400],
      disabled: grey[500],
    },
    textMoreColors: {
      primary: "#555",
      secondary: "#fff", // todo
      tertiary: "#000",
    },
    imageViewTypeBox: {
      byDate: green[100],
      byRoomName: "#fff176",
    },
    divider: grey[400],
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[600],
          },
        },
      },
    },

    MuiTableCell: {
      // changes the divider color for dark mode
      styleOverrides: {
        root: {
          borderColor: grey[600],
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
