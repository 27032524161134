import React, { useEffect, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";
import {
  DashboardHeadCell,
  LayoutContextType,
  UserDocument,
} from "../../../constants/types";
import { Box, DialogContentText } from "@mui/material";
import DashboardTable from "../../../components/common/dashboard/DashboardTable";
import { PersonAdd } from "@mui/icons-material";
import {
  DrawerHeader,
  Main,
} from "../../../components/properties/common/LayoutComponents";
import {
  deleteUser,
  getUsers,
  resendInvitation,
  updateLCUser,
  UpdateUser_API_CALL,
} from "../../../services/apiHelper/users";
import { NumberOfProperties } from "../../../components/common/dashboard/DashCustomCols/NumberOfProperties";
import ThreeDotMenu from "../../../components/common/dashboard/DashCustomCols/ThreeDotMenu";
import { ResetPassword } from "../../../components/users/ResetPassword";
import { CustomDialog } from "../../../components/common/CustomDialog";
import { NotesSidebar } from "../../../components/common/Notes/notesSidebar";
import { TextInputCol } from "../../../components/common/dashboard/DashCustomCols/TextInputCol";

interface AllBorrowersProps {
  // You can define props here if needed. For this simple example, it's empty.
}

const AllBorrowers: React.FC<AllBorrowersProps> = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();
  const navigate = useNavigate();
  const [userIdToUpdate, setUserIdToUpdate] = React.useState<string | null>(
    null
  );
  const [userToDelete, setUserToDelete] = React.useState<UserDocument | null>(
    null
  );
  const [isSidebarOpen, setIsSidebarOpen] = React.useState<boolean>(false);
  const [notesUserId, setNotesUserId] = React.useState<string>("");
  const [textInput, setTextInput] = React.useState<string>("");

  const [userToresend, setUserToResend] = useState<UserDocument | null>(null);
  const [resendInviteError, setResendInviteError] = useState<string | null>(
    null
  );
  const [deleteUserError, setDeleteUserError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [dataRows, setDataRows] = React.useState<UserDocument[] | null>(null);

  useEffect(() => {
    getUsers().then((res) => {
      const filteredUsers = res.users.filter(
        (user: { userType: number }) => user.userType !== 0
      );
      setDataRows(filteredUsers);
    });
  }, []);

  const handleUpdateNotes = async (notes: string) => {
    if (!notesUserId) return;
    const fieldsToUpdate: UpdateUser_API_CALL = { _id: notesUserId, notes };

    try {
      setError(null);
      await updateLCUser(fieldsToUpdate);
      const res = await getUsers();
      const filteredUsers = res.users.filter(
        (user: { userType: number }) => user.userType !== 0
      );
      setDataRows(filteredUsers);

      return true;
    } catch (e) {
      setError("Error updating user notes");
    }
  };

  const handleOnRowClick = (id: string) => {
    navigate(`/gpc/mb/${id}`);
  };

  const handleHeaderButtonClick = () => {
    navigate(`/gpc/mb/newuser`);
  };

  const handleResetPassword = (userId: string) => {
    setUserIdToUpdate(userId);
  };

  const handleUpdateUser = (userId: string) => {
    navigate(`/gpc/mb/${userId}/uu`);
  };

  const handleDeleteUser = async (userId: string) => {
    const user = dataRows?.find((user) => user._id === userId);
    if (!user) return;
    setUserToDelete(user);
  };

  const handleResendInvitation = async (userId: string) => {
    const user = dataRows?.find((user) => user._id === userId);
    if (!user) return;
    setUserToResend(user);
  };

  const handleResendInvitationClick = async () => {
    try {
      setResendInviteError(null);

      if (!userToresend) return;
      const res = await resendInvitation(
        userToresend._id,
        userToresend.username,
        userToresend.email
      );
      return res;
    } catch (error: any) {
      console.error("Error resending invitation:", error);
      setResendInviteError(
        error?.response?.data?.error ||
          "An unexpected error occurred. Reach out to support for assistance."
      );
      throw error;
    }
  };

  const handleDeleteUserClick = async () => {
    try {
      setDeleteUserError(null);
      if (!userToDelete) return;
      await deleteUser(userToDelete._id);
      const res = await getUsers();
      const filteredUsers = res.users.filter(
        (user: { userType: number }) => user.userType !== 0
      );
      setDataRows(filteredUsers);
      return true;
    } catch (error: any) {
      console.error("Error deleting user:", error);
      setDeleteUserError(
        error?.response?.data?.error ||
          "An unexpected error occurred. Reach out to support for assistance."
      );
      throw error;
    }
  };

  const handleCloseDialog = () => {
    setUserIdToUpdate(null);
    setUserToDelete(null);

    setUserToResend(null);
    setResendInviteError(null);

    setUserToDelete(null);
    setDeleteUserError(null);
  };

  const headerButtonData = {
    label: "New User",
    startIcon: <PersonAdd />,

    handleClick: handleHeaderButtonClick,
  };

  const headCells: DashboardHeadCell<UserDocument>[] = [
    {
      id: "firstName",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "First Name",
    },
    {
      id: "lastName",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Last Name",
    },
    {
      id: "email",
      align: "left",
      isSearchable: true,
      disablePadding: true,
      label: "Email",
    },
    {
      id: "propertyList",
      align: "left",
      isSearchable: false,
      disablePadding: true,
      label: "# of Properties",
      customCell: (propertyList) => (
        <NumberOfProperties propertyList={propertyList} />
      ),
    },
    {
      id: "loanProID",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "Loan Pro ID",
    },
    {
      id: "borrowingEntity",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "Borrowing Entity",
    },
    {
      id: "notes",
      align: "left",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: false,
      label: "Notes",
      customCell: (value, _id) => {
        return (
          <TextInputCol
            inputText={value}
            _id={_id}
            handleEditNotes={() => {
              setIsSidebarOpen(true);
              if (_id) setNotesUserId(_id);
              setTextInput(value);
            }}
          />
        );
      },
    },
    {
      id: "_id",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "",
      customCell: (userId) => {
        const user = dataRows?.find((user) => user._id === userId);
        if (!user) return;
        // const userDidNotLogin = !user.lastLogin;
        const userDidNotLogin = false; // todo, remove handleResendInvitation code. Now that user is created with a password, this is not needed
        // because they will always be verified. Therefore, cognito will not allow an invitation to be resent.

        return (
          <ThreeDotMenu
            userId={userId}
            handleResetPassword={handleResetPassword}
            handleUpdateUser={handleUpdateUser}
            handleDeleteUser={handleDeleteUser}
            handleResendInvitation={
              userDidNotLogin ? handleResendInvitation : undefined
            }
          />
        );
      },
    },
  ];

  const RenderDeleteUserDialogContent = () => {
    return (
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to delete{" "}
        {userToDelete?.firstName && userToDelete?.lastName ? (
          <strong>{`${userToDelete.firstName} ${userToDelete.lastName}`}</strong>
        ) : (
          userToDelete?.username || ""
        )}
        ?
      </DialogContentText>
    );
  };
  const RenderUserToResendDialogContent = () => {
    return (
      <DialogContentText id="alert-dialog-description">
        {userToresend?.firstName && userToresend?.lastName ? (
          <strong>{`${userToresend.firstName} ${userToresend.lastName}`}</strong>
        ) : (
          userToresend?.username || ""
        )}{" "}
        will receive another email invitation to join GeoSpec
      </DialogContentText>
    );
  };

  return (
    <>
      <CustomDialog
        isOpen={!!userToresend || !!userToDelete}
        title={userToresend ? "Resend Invitation" : "Delete User"}
        content={{
          initial: userToresend ? (
            <RenderUserToResendDialogContent />
          ) : (
            <RenderDeleteUserDialogContent />
          ),
          successMessage: userToresend
            ? "Invitation resent successfully"
            : "User deleted successfully",
        }}
        submitButton={{
          regular: userToresend ? "Send" : "Delete",
          isSubmitting: userToresend ? "Sending..." : "Deleting...",
        }}
        error={resendInviteError || deleteUserError}
        handleCloseDialog={handleCloseDialog}
        handleClick={
          userToresend ? handleResendInvitationClick : handleDeleteUserClick
        }
      />

      <ResetPassword
        handleCloseDialog={handleCloseDialog}
        userToUpdate={userIdToUpdate}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
        }}
      >
        <Main open={open} drawerWidth={drawerWidth}>
          <NotesSidebar
            isSidebarOpen={isSidebarOpen}
            initialText={textInput}
            handleClose={() => setIsSidebarOpen(false)}
            handleSubmit={handleUpdateNotes}
            error={error}
          />
          <DrawerHeader />
          <DashboardTable
            dataRows={dataRows}
            headCells={headCells}
            fuseThreshold={0.4}
            handleOnRowClick={handleOnRowClick}
            headerButtonData={headerButtonData}
            noRowsMessage={{
              title: "No borrowers yet",
              subtitle: "Click 'New User' to add a borrower",
            }}
          />
        </Main>
      </Box>
    </>
  );
};

export default AllBorrowers;
