import React, { useEffect, useRef, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";
import {
  DashboardHeadCell,
  LayoutContextType,
  PropertyDocument,
} from "../../constants/types";
import { Alert, Box, DialogContentText } from "@mui/material";
import DashboardTable from "../../components/common/dashboard/DashboardTable";
import {
  DrawerHeader,
  Main,
} from "../../components/properties/common/LayoutComponents";
import {
  addFileToProperty,
  archiveProperty,
  getLCProperties,
  updateProperty,
} from "../../services/apiHelper/properties";
import { AddressCol } from "../../components/common/dashboard/DashCustomCols/AddressCol";
import { TextInputCol } from "../../components/common/dashboard/DashCustomCols/TextInputCol";
import store from "../../ts/store";
import { LocationCity } from "@mui/icons-material";
import { NotesSidebar } from "../../components/common/Notes/notesSidebar";
import { ScopeOfWork } from "../../components/common/dashboard/DashCustomCols/ScopeOfWork";
import { RemoveItem } from "../../components/common/dashboard/DashCustomCols/RemoveItem";
import { CustomDialog } from "../../components/common/CustomDialog";
interface HomePageProps {
  // You can define props here if needed. For this simple example, it's empty.
}

const HomePage: React.FC<HomePageProps> = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [notesPropertyId, setNotesPropertyId] = useState<String>("");
  const [textInput, setTextInput] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [propertyToArchive, setPropertyToArchive] =
    useState<PropertyDocument | null>(null);

  const [filePropertyId, setFilePropertyId] = useState<string | null>(null);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [dataRows, setDataRows] = useState<PropertyDocument[] | null>(null);

  const headCells: DashboardHeadCell<PropertyDocument>[] = [
    {
      id: "address",
      align: "left",

      isSearchable: true,
      disablePadding: false,
      label: "Address",
      customCell: (address) => <AddressCol addressVal={address} />,
    },
    {
      id: "notes",
      align: "left",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: false,
      label: "Notes",
      customCell: (value, _id) => {
        return (
          <TextInputCol
            inputText={value}
            _id={_id}
            handleEditNotes={() => {
              setIsSidebarOpen(true);
              if (_id) setNotesPropertyId(_id);
              setTextInput(value);
            }}
          />
        );
      },
    },
    {
      id: "scopeOfWork",
      align: "center",
      headerAlign: "center",
      isSearchable: true,
      disablePadding: true,
      label: "Scope of Work",
      customCell: (value, _id) => {
        const isLoading = isSubmitting && filePropertyId === _id;
        const propertyAddress = dataRows?.find((p) => p._id === _id)?.address;
        const addressString = `${propertyAddress?.streetLine1}, ${propertyAddress?.city}, ${propertyAddress?.state}`;
        return (
          <ScopeOfWork
            fileUrl={value}
            isLoading={isLoading}
            propertyAddress={addressString}
            onUpload={() => {
              if (_id) setFilePropertyId(_id);
              fileInputRef.current?.click();
            }}
          />
        );
      },
    },
    {
      id: "_id",
      align: "left",
      isSearchable: true,
      disablePadding: false,
      label: "",
      customCell: (propertyId) => {
        const property = dataRows?.find((p) => p._id === propertyId);

        return (
          <RemoveItem
            handleDeleteClick={() => setPropertyToArchive(property ?? null)}
          />
        );
      },
    },
  ];

  const handleUpdateNotes = async (notes: string) => {
    if (!notesPropertyId) return;

    try {
      setError(null);
      await updateProperty(notesPropertyId, { notes });
      getLCProperties().then((res) => {
        setDataRows(res.properties);
      });
      return true;
    } catch (e) {
      setError("Error updating user notes");
    }
  };

  useEffect(() => {
    getLCProperties().then((res) => {
      setDataRows(res.properties);
    });

    // clear out the property data in redux
    // this is to prevent stale data from showing up when
    // you navigate back to this page
    store.dispatch({
      type: "UPDATE_PROPERTY_INSPECTIONS",
      propertyInspectionsData: null,
    });
    store.dispatch({
      type: "UPDATE_PROPERTY_ROOMS",
      propertyInspectionsData: null,
    });
  }, []);

  const handleFileInput = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();

    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      if (file.type !== "application/pdf") {
        setFileUploadError("Only PDF files are allowed.");
        return;
      }
      try {
        setFileUploadError(null);
        setIsSubmitting(true);
        if (!filePropertyId) {
          throw new Error("No property ID found for file upload");
        }
        await addFileToProperty(filePropertyId, file);
        getLCProperties().then((res) => {
          setDataRows(res.properties);
        });
      } catch (error: any) {
        setFileUploadError(
          error?.response?.data?.error ||
            "Error uploading file. Please try again."
        );
        console.error("Error uploading file:", error);
      } finally {
        // Clear the file input to allow re-uploading the same file
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Reset the file input
        }
        setIsSubmitting(false);
      }
    }
  };

  const handleOnRowClick = (id: string) => {
    navigate(`/gpc/p/0/${id}`);
  };

  const handleHeaderButtonClick = () => {
    navigate(`/gpc/p/ap`);
  };

  const headerButtonData = {
    label: "New Property",
    startIcon: <LocationCity />,

    handleClick: handleHeaderButtonClick,
  };

  const handleArchiveInspection = async () => {
    try {
      if (!propertyToArchive) return;

      const archivedProperty = await archiveProperty(propertyToArchive._id);
      getLCProperties().then((res) => {
        setDataRows(res.properties);
      });
      return archivedProperty;
    } catch (error) {
      throw error;
    }
  };

  const handleCloseConfirmDialog = () => {
    setPropertyToArchive(null);
  };

  const RenderDialogContent = () => {
    return (
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to archive the property{" "}
        <strong>
          {`${propertyToArchive?.address?.streetLine1}, ${propertyToArchive?.address?.city}, ${propertyToArchive?.address?.state}`}
        </strong>
        ? <br /> <br /> The property will no longer be visible in the list.
      </DialogContentText>
    );
  };

  return (
    <>
      <CustomDialog
        isOpen={!!propertyToArchive}
        title="Archive Property"
        content={{
          initial: <RenderDialogContent />,
          successMessage: "Property archived successfully",
        }}
        submitButton={{
          regular: "Archive",
          isSubmitting: "Archiving...",
        }}
        handleCloseDialog={handleCloseConfirmDialog}
        handleClick={handleArchiveInspection}
      />
      <input
        accept="application/pdf"
        style={{ display: "none" }}
        type="file"
        ref={fileInputRef}
        onChange={handleFileInput}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
        }}
      >
        <Main open={open} drawerWidth={drawerWidth}>
          <DrawerHeader />
          {fileUploadError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {fileUploadError}
            </Alert>
          )}
          <DashboardTable
            dataRows={dataRows}
            headCells={headCells}
            fuseThreshold={0.4}
            handleOnRowClick={handleOnRowClick}
            headerButtonData={headerButtonData}
            noRowsMessage={{
              title: "No properties yet",
              subtitle: "Click 'New Property' to get started",
            }}
          />
          <NotesSidebar
            isSidebarOpen={isSidebarOpen}
            initialText={textInput}
            error={error}
            handleClose={() => setIsSidebarOpen(false)}
            handleSubmit={handleUpdateNotes}
          />
        </Main>
      </Box>
    </>
  );
};

export default HomePage;
