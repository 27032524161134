import React from "react";
import AllRoutes from "./routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ColorMode } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css"; // this needs to be imported after the @aws-amplify/ui-react styles

import { ThemeProvider as AmplifyThemeProvider } from "@aws-amplify/ui-react";

import { CustomAmplifyTheme } from "./components/AmplifyCustomAuth/CustomTheme";
import { ThemeProvider, useMediaQuery } from "@mui/material";
import { darkTheme, lightTheme } from "./theme/theme";
import { Provider as ReduxProvider } from "react-redux";
import store from "./ts/store";

function App() {
  const [colorMode, setColorMode] = React.useState<ColorMode>("system");
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <AmplifyThemeProvider theme={CustomAmplifyTheme} colorMode={colorMode}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ReduxProvider store={store}>
          <ThemeProvider theme={prefersDarkMode ? darkTheme : lightTheme}>
            <AllRoutes />
          </ThemeProvider>
        </ReduxProvider>
      </LocalizationProvider>
    </AmplifyThemeProvider>
  );
}

export default App;
