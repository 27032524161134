// NotificationMenu.tsx
import React, { Fragment } from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { NotificationDocument } from "../../constants/types";
import { blue } from "@mui/material/colors";
import { markNotificationAsRead } from "../../services/apiHelper/notifications";
import { useNavigate } from "react-router-dom";

interface NotificationMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: (event: any) => void;
  notifications: NotificationDocument[];
  setNotificationsData: React.Dispatch<
    React.SetStateAction<NotificationDocument[]>
  >;
}
export const NotificationMenu: React.FC<NotificationMenuProps> = ({
  anchorEl,
  open,
  handleClose,
  notifications,
  setNotificationsData,
}) => {
  const navigate = useNavigate();
  const handleNotificationClick = async (
    originalNotificationData: NotificationDocument
  ) => {
    try {
      if (!originalNotificationData.read) {
        const notificationData: NotificationDocument =
          await markNotificationAsRead(originalNotificationData._id);
        const updatedNotifications = notifications.map((notification) => {
          return notification._id === originalNotificationData._id
            ? { ...notificationData }
            : notification;
        });

        setNotificationsData(updatedNotifications);
      }
      const propertyId = originalNotificationData.sender;
      navigate(`/gpc/p/1/${propertyId}`);
    } catch (error) {
      console.error("Error updating notification: ", error);
    }

    handleClose(null);
  };

  const renderMenuItems = () => {
    return notifications.flatMap((notification, index) => [
      <MenuItem
        key={notification._id}
        onClick={() => handleNotificationClick(notification)}
        sx={{
          whiteSpace: "normal",
          my: 1,
        }}
      >
        <ListItemText
          primary={
            <Typography variant="body2" noWrap={false} component="div">
              {notification.data
                .split("completed a draw for the property")
                .map((text, index, array) =>
                  index < array.length - 1 ? (
                    <Fragment key={index}>
                      <strong>{text}</strong> completed a draw for the property
                    </Fragment>
                  ) : (
                    <strong key={index}>{text}</strong>
                  )
                )}
            </Typography>
          }
        />
        {!notification.read && (
          <ListItemIcon>
            <FiberManualRecord fontSize="small" sx={{ color: blue[400] }} />
          </ListItemIcon>
        )}
      </MenuItem>,
      index < notifications.length - 1 && (
        <Divider key={"divider-" + notification._id} sx={{ mx: 2 }} />
      ),
    ]);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 700,
          width: "35ch",
          overflow: "auto",
        },
      }}
    >
      {renderMenuItems()}
    </Menu>
  );
};
