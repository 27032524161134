import React from "react";
import { TableCell, Typography } from "@mui/material";

type UserTypeStringProps = {
  value: string;
};

export const CustomString: React.FC<UserTypeStringProps> = ({ value }) => {
  return (
    <TableCell align="left">
      <Typography component="span" sx={{ color: "text.primary" }}>
        {value}
      </Typography>
    </TableCell>
  );
};
