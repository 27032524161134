import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Box,
  Alert,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import AddProperties from "./AddProperties";
import { PropertyDataWithMapAPI } from "../../constants/types";
import { UserType_API_CALL, createUser } from "../../services/apiHelper/users";

interface CreateUserFormProps {}

const DEFAULT_USER: UserType_API_CALL = {
  firstName: "",
  lastName: "",
  email: "",
  loanProID: "",
  borrowingEntity: "",
  password: "Welcome2024",
  userType: 1,
};

const CreateUserForm: React.FC<CreateUserFormProps> = () => {
  const [user, setUser] = useState<UserType_API_CALL>(DEFAULT_USER);
  const [error, setError] = useState<string | null>(null); // State to store the error message
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [inspectionFrequency, setInspectionFrequency] = useState<string>("");
  const [properties, setProperties] = useState<PropertyDataWithMapAPI[]>([]);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const handleChange =
    (prop: keyof UserType_API_CALL) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === "password") {
        const errors = passwordValidation(event.target.value);
        setPasswordErrors(errors);
      }

      setUser({ ...user, [prop]: event.target.value });
    };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Reset messages before the API call
    setError(null);
    setIsSubmitting(true);
    setIsSuccess(false);
    try {
      await createUser(user);
      setIsSuccess(true);
      setUser(DEFAULT_USER);
    } catch (error: any) {
      setError(
        error?.response?.data?.error ||
          "An unexpected error occurred. Reach out to support for assistance."
      );
      setIsSubmitting(false);
      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  const passwordValidation = (password: string): string[] => {
    const errors: string[] = [];

    if (!/[A-Z]/.test(password)) {
      errors.push("Password should contain at least one uppercase character.");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password should contain at least one lowercase character.");
    }
    if (!/\d/.test(password)) {
      errors.push("Password should contain at least one number.");
    }
    if (password.length < 8) {
      errors.push("Password should be at least 8 characters long.");
    }

    return errors;
  };

  const UserTypeRadio = () => (
    <FormControl>
      <FormLabel id="user-type-label">User Type</FormLabel>
      <RadioGroup
        row
        aria-labelledby="user-type-label"
        name="userType"
        value={user.userType.toString()}
        onChange={(event) =>
          setUser({ ...user, userType: parseInt(event.target.value, 10) })
        }
      >
        <FormControlLabel value="1" control={<Radio />} label="Borrower" />

        <FormControlLabel
          value="0"
          control={<Radio />}
          label="Wisdom Capital Lender"
        />
      </RadioGroup>
    </FormControl>
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Create New User
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            rowSpacing={6}
            columnSpacing={2.75}
            justifyContent="space-between"
          >
            {/* User Info Inputs */}
            <Grid container item rowSpacing={2} columnSpacing={2.75} xs={12}>
              <Grid item xs={12} sm={3} md={3.5} lg={3}>
                <TextField
                  label="Enter First Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={user.firstName}
                  onChange={handleChange("firstName")}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3.5} lg={3}>
                <TextField
                  label="Enter Last Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={user.lastName}
                  onChange={handleChange("lastName")}
                />
              </Grid>
              <Grid container item rowSpacing={2} columnSpacing={2.75} xs={12}>
                <Grid item xs={12} sm={7} md={6} lg={5}>
                  <TextField
                    label="Enter Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={user.email}
                    onChange={handleChange("email")}
                  />
                </Grid>
              </Grid>

              <Grid container item rowSpacing={2} columnSpacing={2.75} xs={12}>
                <Grid item xs={12} sm={7} md={6} lg={5}>
                  <TextField
                    label="Password"
                    variant="outlined"
                    value={user.password}
                    onChange={handleChange("password")}
                    fullWidth
                    required
                    error={passwordErrors.length > 0}
                    helperText={
                      passwordErrors.length > 0 ? (
                        <ul style={{ margin: 0, paddingLeft: 20 }}>
                          {passwordErrors.map((error, index) => (
                            <li key={index} style={{ color: "red" }}>
                              {error}
                            </li>
                          ))}
                        </ul>
                      ) : null
                    }
                  />
                </Grid>
              </Grid>

              {user.userType === 1 && (
                <>
                  <Grid
                    container
                    item
                    rowSpacing={1}
                    columnSpacing={2.75}
                    xs={12}
                  >
                    <Grid item xs={12} sm={5} md={4.5} lg={4}>
                      <TextField
                        label="LoanPro ID"
                        variant="outlined"
                        value={user.loanProID}
                        onChange={handleChange("loanProID")}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      rowSpacing={1}
                      columnSpacing={2.75}
                      xs={12}
                    ></Grid>

                    <Grid item xs={12} sm={5} md={4.5} lg={4}>
                      <TextField
                        label="Borrowing Entity"
                        variant="outlined"
                        value={user.borrowingEntity}
                        onChange={handleChange("borrowingEntity")}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <UserTypeRadio />
              </Grid>
            </Grid>

            {/* Property Info Inputs */}
            <AddProperties
              startDateDate={{ selectedDate, setDate: setSelectedDate }}
              inspectionFrequencyData={{
                inspectionFrequency,
                setFrequency: setInspectionFrequency,
              }}
              propertiesStateData={{ properties, setProperties }}
            />

            {/* Save Button */}
            <Grid item xs={12}>
              <Box sx={{ padding: 1, textAlign: "center", borderRadius: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disableRipple
                >
                  Save
                </Button>
                {/* Error Alert */}
                {error && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                  </Alert>
                )}
                {isSubmitting && (
                  <Alert severity="info" sx={{ mt: 2 }}>
                    Creating user...
                  </Alert>
                )}
                {isSuccess && (
                  <Alert severity="success" sx={{ mt: 2 }}>
                    User created successfully
                  </Alert>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateUserForm;
