import React from "react";
import { TableCell, Typography } from "@mui/material";
import { Address as AddressType } from "../../../../constants/types";

export const AddressCol: React.FC<{ addressVal: AddressType }> = ({
  addressVal,
}) => (
  <TableCell align="left">
    <Typography component="span" sx={{ color: "text.primary" }}>
      {addressVal.streetLine1},
    </Typography>{" "}
    <Typography component="span" sx={{ color: "text.secondary" }}>
      {addressVal.city},
    </Typography>{" "}
    <Typography component="span" sx={{ color: "text.secondary" }}>
      {addressVal.state}
    </Typography>
  </TableCell>
);
