import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import {
  LayoutContextType,
  PropertyDocument,
  RoomInspectionsData,
  ViewType,
} from "../constants/types";
import { Box, Grid } from "@mui/material";
import InspectionsRoomsGrid from "../components/properties/InspectionsRoomsGrid";
import InspectionList from "../components/properties/InspectionList";
import PropertyDetailsHeader from "../components/properties/common/PropertyDetailsHeader";
import {
  Main,
  DrawerHeader,
} from "../components/properties/common/LayoutComponents";
import {
  getPropertyRooms,
  getLCProperties,
} from "../services/apiHelper/properties";
import { useSelector } from "react-redux";
import {
  selectLCPropertiesData,
  selectPropertyInspectionsData,
  selectPropertyRoomsData,
} from "../ts/selectors";
import {
  getInspectionImages,
  getPropertyInspections,
} from "../services/apiHelper/inspections";
import UsersInPropertyTable from "../components/users/UsersInPropertyTable";
import CustomBreadcrumbs from "../components/navbar/CustomBreadcrumbs";

const PropertyDetails: React.FC = () => {
  const { open, drawerWidth } = useOutletContext<LayoutContextType>();
  const { viewType: viewTypeParam, propertyId: propertyIdParam } = useParams<{
    viewType?: string;
    propertyId?: string;
  }>();

  const viewType = viewTypeParam === "0" ? ViewType.LIST : ViewType.GRID;

  const lcPropertiesData = useSelector(selectLCPropertiesData);
  const propertyRoomsData = useSelector(selectPropertyRoomsData);
  const propertyInspectionsData = useSelector(selectPropertyInspectionsData);
  const [propertyData, setPropertyData] = useState<PropertyDocument | null>(
    null
  );
  const [inspectionImagesRooms, setInspectionImagesRooms] = useState<
    RoomInspectionsData[] | null
  >(null);

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (propertyIdParam) {
        await Promise.all([
          getPropertyRooms(propertyIdParam),
          getPropertyInspections(propertyIdParam),
        ]);
      }
    };

    fetchPropertyData();
  }, [propertyIdParam]);

  useEffect(() => {
    const fetchInspectionImages = async () => {
      if (propertyRoomsData) {
        try {
          const inspectionImagesPromises = propertyRoomsData.map(
            async (room) => {
              const inspectionImages = await getInspectionImages({
                roomId: room._id,
              });
              return { room, inspectionImages };
            }
          );

          const inspectionImagesRoomsData = await Promise.all(
            inspectionImagesPromises
          );
          setInspectionImagesRooms(inspectionImagesRoomsData);
        } catch (error) {
          console.error("Error fetching inspection images:", error);
        }
      }
    };

    fetchInspectionImages();
  }, [propertyRoomsData]);

  useEffect(() => {
    if (!lcPropertiesData) {
      getLCProperties();
    } else if (propertyIdParam) {
      const property = lcPropertiesData.find(
        (property) => property._id === propertyIdParam
      );
      setPropertyData(property || null);
    }
  }, [lcPropertiesData, propertyIdParam]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      <Main open={open} drawerWidth={drawerWidth}>
        <DrawerHeader />
        <CustomBreadcrumbs />

        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <PropertyDetailsHeader
            viewType={viewType}
            propertyData={propertyData}
          />
          <Grid item xs={12}>
            {viewType === ViewType.GRID ? (
              <InspectionsRoomsGrid
                inspectionImagesRooms={inspectionImagesRooms}
                propertyInspectionsData={
                  propertyInspectionsData ? propertyInspectionsData : []
                }
              />
            ) : (
              <InspectionList
                propertyInspectionsData={propertyInspectionsData}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            {propertyData?.userList && (
              <UsersInPropertyTable
                userList={propertyData.userList}
                propertyId={propertyData._id}
              />
            )}
          </Grid>
        </Grid>
      </Main>
    </Box>
  );
};

export default PropertyDetails;
