import React from "react";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

type RemoveItemProps = {
  buttonDisabled?: boolean;

  handleDeleteClick: () => void;
};

export const RemoveItem: React.FC<RemoveItemProps> = ({
  buttonDisabled,
  handleDeleteClick,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleDeleteClick();
  };

  return (
    <TableCell align="center">
      <Tooltip
        title={
          <h2 style={{ color: grey[300] }}>
            Cannot remove other lenders from property
          </h2>
        }
        placement="top"
        arrow
        disableHoverListener={!buttonDisabled}
      >
        <div>
          <IconButton
            aria-label="more options"
            onClick={handleClick}
            disabled={buttonDisabled}
            sx={{
              paddingX: "20px",
              borderRadius: "5%",
            }}
          >
            <Clear
              color={buttonDisabled ? "disabled" : "error"}
              sx={{ margin: 0 }}
            />
          </IconButton>
        </div>
      </Tooltip>
    </TableCell>
  );
};
