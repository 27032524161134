import React from "react";
import { Tab, Tabs, styled } from "@mui/material";
import { GridView, ViewList } from "@mui/icons-material";
import { ViewType } from "../../constants/types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBasePath } from "../../utils/navigation.util";

interface ViewSwitcherProps {
  viewType: ViewType;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
  icon: React.ReactComponentElement<any>;
  iconPosition: "start" | "end";
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 70, // todo remove if not needed
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },

  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const ViewSwitcher: React.FC<ViewSwitcherProps> = ({ viewType }) => {
  const navigate = useNavigate();
  const basePath = useBasePath();
  const { propertyId: propertyIdParam } = useParams<{
    propertyId?: string;
  }>();

  const handleChange = (event: React.SyntheticEvent, newValue: ViewType) => {
    const newViewType =
      viewType === ViewType.GRID ? ViewType.LIST : ViewType.GRID;
    const newPath = `${basePath}/${newViewType}/${propertyIdParam}`;

    navigate(newPath);
  };

  return (
    <StyledTabs
      value={viewType}
      onChange={handleChange}
      aria-label="styled tabs example"
    >
      <StyledTab label="Inspections" icon={<ViewList />} iconPosition="start" />
      <StyledTab label="Rooms" icon={<GridView />} iconPosition="start" />
    </StyledTabs>
  );
};

export default ViewSwitcher;
