import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ConfirmDialog from "../confirmDialog";
import CountdownTimer from "../CountdownTimer";

type NotesSidebarProps = {
  isSidebarOpen: boolean;
  initialText: string;
  error?: string | null;
  title?: {
    title?: string;
    description?: string;
  };

  handleClose: () => void;
  handleSubmit: (updatedNotes: string) => Promise<any>;
};

export const NotesSidebar = ({
  isSidebarOpen,
  initialText,
  error,
  title,
  handleClose,
  handleSubmit,
}: NotesSidebarProps) => {
  const [inputText, setInputText] = useState<string>(initialText);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [isTextEdited, setIsTextEdited] = useState<boolean>(false);
  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Loading state
  const [isSuccess, setIsSuccess] = useState<boolean>(false); // Success state

  useEffect(() => {
    setInputText(initialText);
  }, [initialText]);

  useEffect(() => {
    if (isSidebarOpen) {
      setIsSuccess(false);
      setIsSubmitting(false);
      setIsTextEdited(false);
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.focus();
          const length = textFieldRef.current.value.length;
          textFieldRef.current.setSelectionRange(length, length);
        }
      }, 300); // Set a small delay to ensure the drawer is fully open
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    setIsTextEdited(inputText !== initialText);
  }, [inputText, initialText]);

  const handleCloseSidebar = () => {
    if (isTextEdited) {
      setOpenConfirmDialog(true);
    } else {
      handleClose();
    }
  };

  const handleConfirmDiscard = () => {
    setOpenConfirmDialog(false);
    setInputText(initialText);
    handleClose();
  };

  const handleCancelDiscard = () => {
    setOpenConfirmDialog(false);
    handleSubmit(inputText);
  };

  const handleSubmitNotes = async () => {
    setIsSubmitting(true);
    setIsSuccess(false);
    try {
      const res = await handleSubmit(inputText);

      if (res) {
        setOpenConfirmDialog(false);
        setIsTextEdited(false);
        setIsSuccess(true);
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Drawer anchor="right" open={isSidebarOpen} onClose={handleCloseSidebar}>
        <Box sx={{ width: 600, padding: 2 }} role="presentation">
          <Typography variant="h6">
            {title?.title ?? "Add Your Notes"}
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            value={inputText}
            inputRef={textFieldRef}
            onChange={(e) => setInputText(e.target.value)}
            label={title?.description ?? "Enter notes here"}
            disabled={isSubmitting}
          />

          {/* Loading spinner */}
          {isSubmitting && (
            <Box sx={{ display: "flex", alignItems: "center", marginY: 1 }}>
              <CircularProgress size={24} />
              <Typography sx={{ marginLeft: 2 }}>Submitting...</Typography>
            </Box>
          )}

          {/* Success message */}
          {isSuccess && (
            <Alert severity="success" sx={{ marginY: 1 }}>
              Updated!
            </Alert>
          )}

          {/* Error message */}
          {error && (
            <Alert severity="error" sx={{ marginY: 1 }}>
              {error}
            </Alert>
          )}
          {isSuccess && (
            <CountdownTimer
              duration={3}
              onComplete={handleClose} // Close the dialog when countdown finishes
            />
          )}

          <Button
            variant="contained"
            onClick={handleSubmitNotes}
            sx={{ marginY: 1, marginRight: 4 }}
            disabled={isSubmitting}
          >
            Submit
          </Button>
          <Button variant="outlined" onClick={handleCloseSidebar}>
            Close
          </Button>
        </Box>
      </Drawer>

      <ConfirmDialog
        open={openConfirmDialog}
        dialogText={{
          title: isSubmitting ? "Submitting Changes" : "Unsaved Changes",
          description: isSubmitting
            ? "Please wait while we submit your changes"
            : "Are you sure you want to discard your changes?",
          cancel: "Cancel",
          confirm: "Discard",
        }}
        onClose={handleCancelDiscard}
        onConfirmAction={handleConfirmDiscard}
      />
    </>
  );
};
