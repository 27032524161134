// src/Survey.tsx

import React, { useState } from "react";
import {
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import AddProperties from "./AddProperties";
import { PropertyDataWithMapAPI } from "../../constants/types";
import {
  PropertyDocument_API_CALL,
  createProperty,
} from "../../services/apiHelper/properties";
import { useParams } from "react-router-dom";
import UserSelectorTable from "./UserSelectorTable";
import AddExistingPropertiesToUser from "../properties/AddExistingPropertiesToUser";

interface AddPropertyProps {
  selectedUserData:
    | {
        title: string;
        subtitle: string;
      }
    | undefined;
}

const AddPropertyForm: React.FC<AddPropertyProps> = ({ selectedUserData }) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [inspectionFrequency, setInspectionFrequency] =
    React.useState<string>("");
  const [properties, setProperties] = React.useState<PropertyDataWithMapAPI[]>([
    { id: 0, address: null },
  ]);

  const [error, setError] = useState<string | null>(null); // State to store the error message
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { userId: userIdParam } = useParams<{ userId?: string }>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSaveProperty = async () => {
    setError(null);
    setIsSubmitting(true);
    setIsSuccess(false);

    if (!userIdParam && (!selectedIds || selectedIds.length === 0)) {
      setError("Please select users");
      setIsSubmitting(false);
      return;
    }

    try {
      for (const property of properties) {
        if (property.address !== null) {
          const newPropertyData: PropertyDocument_API_CALL = {
            address: property.address?.address_components,
            location: property.address.geometry.location,
            viewport: property.address.geometry.viewport,
            notes: undefined, // todo add notes
            loanAllocation: property.loanAmount ?? 0,
            loanEndDate: property.loanEndDate ?? new Date(),
            userIds: userIdParam ? [userIdParam] : selectedIds,
          };
          await createProperty(newPropertyData);
        } else {
          setError("All fields must be completed before saving.");
          setIsSubmitting(false);
          return; // Stop the function if any field is missing
        }
      }
      setIsSuccess(true);
      setProperties([{ id: 0, address: null }]); // Reset properties
      setSelectedIds([]);
    } catch (error: any) {
      setError(
        error.response.data.error ||
          "An unexpected error occurred while creating property. Reach out to support for assistance."
      );
      setIsSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Card>
      <CardContent>
        {userIdParam && selectedUserData && (
          <AddExistingPropertiesToUser
            headerTitleData={selectedUserData}
            userId={userIdParam}
          />
        )}
        <Box mb={5}>
          <Typography
            variant={userIdParam ? "h6" : "h5"}
            sx={{ color: "text.primary" }}
          >
            Create New Property
          </Typography>
        </Box>

        <Grid
          container
          rowSpacing={6}
          columnSpacing={2.75}
          justifyContent="space-between"
        >
          {/* Property Info Inputs */}
          <AddProperties
            startDateDate={{ selectedDate, setDate: setSelectedDate }}
            inspectionFrequencyData={{
              inspectionFrequency,
              setFrequency: setInspectionFrequency,
            }}
            propertiesStateData={{ properties, setProperties }}
            defaultOneProperty={true}
          />
          {!userIdParam && (
            <Grid item xs={12}>
              <UserSelectorTable
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                multiSelect={true}
              />
            </Grid>
          )}
          {/* Save Button */}

          <Grid item xs={12}>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            {isSubmitting && (
              <Alert severity="info" sx={{ mt: 2 }}>
                Creating property...
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Property created successfully
              </Alert>
            )}

            <Box
              sx={{
                padding: 1,
                textAlign: "center",
                borderRadius: 3,
                marginTop: 2,
              }}
            >
              <Button
                variant="contained"
                size="large"
                onClick={handleSaveProperty}
                disableRipple
                disabled={isSubmitting}
              >
                Save Property
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AddPropertyForm;
