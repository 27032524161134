import {
  styled,
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { People, AdminPanelSettings, LocationCity } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

const menuItems1 = [
  { text: "Properties", icon: <LocationCity />, path: "/gpc/p" },
  { text: "Manage Borrowers", icon: <People />, path: "/gpc/mb" },
];

const menuItems2 = [
  { text: "Lenders", icon: <AdminPanelSettings />, path: "/gpc/ml" },
];

const SideBar = ({
  open,
  drawerWidth = 240,
}: {
  open: boolean;
  drawerWidth?: number;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          sx={{
            backgroundColor: "secondary.dark",
            height: "57px",
            display: "flex",
            cursor: "pointer",
            transition: "opacity 0.2s",
            "&:hover": {
              opacity: 0.7,
            },
          }}
        >
          <img
            // src="https://wisdomcapitalusa.com/storage/app/public/Frontassets/images/wisdom_capital_logo.png"
            src="https://pikcheck-static-files.s3.amazonaws.com/logo-images/logo_wide_v1_dm.png"
            alt="company logo"
            style={{
              objectFit: "contain",
              height: "85%",
              width: "auto",
            }}
            onClick={() => {
              navigate("/gpc/p");
            }}
          />
        </DrawerHeader>
        <List style={{ marginTop: "15px" }}>
          {menuItems1.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                }}
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  "&:hover": {
                    bgcolor: "secondary.light",
                  },
                  "&.Mui-selected": {
                    bgcolor: "secondary.light",
                    borderRight: "2px solid",
                    borderColor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.light",
                    },
                  },
                }}
              >
                <ListItemIcon sx={{ color: "text.primary", opacity: 0.5 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List>
          <Divider />
          {menuItems2.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                }}
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  "&:hover": {
                    bgcolor: "secondary.light",
                  },
                  "&.Mui-selected": {
                    bgcolor: "secondary.light",
                    borderRight: "2px solid",
                    borderColor: "secondary.main",
                    "&:hover": {
                      bgcolor: "secondary.light",
                    },
                  },
                }}
              >
                <ListItemIcon sx={{ color: "text.primary", opacity: 0.5 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default SideBar;
