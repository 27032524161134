import ApiService from "../api";
import { BASEPATHS } from "../../config";
import store from "../../ts/store";

export const markNotificationAsRead = async (notificationId: string) => {
  const apiService = new ApiService();
  try {
    const notificationData = await apiService.post<any>({
      path: "/markNotificationAsRead",
      dataType: BASEPATHS.notification,
      data: {
        notificationId: notificationId,
      },
    });

    return notificationData.notification;
  } catch (error) {
    console.error("Error creating property:", error);
    throw error;
  }
};

export const getNotifications = async ({ read }: { read?: boolean }) => {
  const apiService = new ApiService();

  try {
    const notificationsData = await apiService.get<any>({
      path: `/getNotifications`,
      dataType: BASEPATHS.notification,
      data: {
        read,
      },
    });

    return notificationsData.notificationData;
  } catch (error) {
    console.error("Error getting notifcations:", error);
    throw error; // Rethrow the error to be handled by the caller
  }
};
