import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ViewSwitcher from "../ViewSwitcher";
import {
  Address,
  ImageViewType,
  InspectionDocument,
  PropertyDocument,
  ViewType,
} from "../../../constants/types";
import { updateInspection } from "../../../services/apiHelper/inspections";
import { NotesSidebar } from "../../common/Notes/notesSidebar";
import NotesDisplay from "../../common/Notes/NotesDisplay";
import {
  getLCProperties,
  updateProperty,
} from "../../../services/apiHelper/properties";

interface HeaderProps {
  propertyData: PropertyDocument | null;
  viewType?: ViewType;
  titleData?: string;
  inspectionData?: InspectionDocument | null;
  imageViewType?: ImageViewType;
}

export const formatAddress = (
  address?: Address,
  addressParts?: (keyof Address)[]
) => {
  if (!address) return "Loading...";

  const defaultParts: (keyof Address)[] = [
    "streetLine1",
    "streetLine2",
    "city",
    "state",
    "zip",
  ];

  const partsToUse = addressParts || defaultParts;

  // Filter out any undefined or null parts and join the remaining parts
  const formattedAddress = partsToUse
    .map((part) => address[part])
    .filter(Boolean)
    .join(", ");

  return formattedAddress || "Address not available";
};

const PropertyPageBody: React.FC<HeaderProps> = ({
  viewType,
  propertyData,
  inspectionData,
  titleData,
  imageViewType,
}) => {
  const [isInspSidebarOpen, setInspIsSidebarOpen] = useState<boolean>(false);
  const [isPropSidebarOpen, setIsPropSidebarOpen] =
    React.useState<boolean>(false);
  const [textInput, setTextInput] = React.useState<string>("");
  const [error, setError] = useState<string | null>(null); // State to store the error message

  const theme = useTheme();

  const openSidebar = () => {
    if (imageViewType === undefined) {
      setIsPropSidebarOpen(true);
      setTextInput(propertyData?.notes || "");
    } else {
      setInspIsSidebarOpen(true);
      setTextInput(inspectionData?.notes || "");
    }
  };

  // Close the sidebar
  const closeSidebar = () => {
    setInspIsSidebarOpen(false);
    setIsPropSidebarOpen(false);
    setTextInput("");
  };

  const handleUpdatePropNotes = async (notes: string) => {
    if (!propertyData?._id) return;
    try {
      setError(null);
      await updateProperty(propertyData?._id, { notes });
      getLCProperties();

      return true;
    } catch (e) {
      setError("Error updating user notes");
    }
  };

  const handleUpdateInspNotes = async (updatedNotes: String) => {
    if (inspectionData?._id)
      try {
        setError(null);
        await updateInspection({
          inspectionId: inspectionData._id,
          notes: updatedNotes,
        });

        return true;
      } catch (e) {
        setError("Error updating user notes");
      }
  };

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        spacing={2}
      >
        <Grid container item xs={3}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: "text.primary" }}>
              {formatAddress(propertyData?.address, [
                "streetLine1",
                "streetLine2",
              ])}
            </Typography>
            <Typography variant="h6" sx={{ color: "text.secondary" }}>
              {formatAddress(propertyData?.address, ["city", "state", "zip"])}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            alignSelf: "flex-start",
          }}
        >
          {titleData && (
            <Box
              sx={{
                backgroundColor:
                  imageViewType === ImageViewType.ByDate
                    ? "imageViewTypeBox.byDate"
                    : "imageViewTypeBox.byRoomName",
                opacity: theme.palette.mode === "dark" ? 0.9 : undefined,

                p: 1,
                textAlign: "center",
              }}
            >
              <Typography variant="h5" sx={{ color: "textMoreColors.primary" }}>
                Viewing
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: "textMoreColors.tertiary" }}
              >
                {titleData}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      {imageViewType === ImageViewType.ByRoomName && (
        <Grid item xs={12}>
          <NotesDisplay
            openSidebar={openSidebar}
            notes={inspectionData?.notes}
            title="Inspection message for borrowers"
            buttonTitle="Send Borrower Message"
          />
        </Grid>
      )}

      {imageViewType === undefined && (
        <Grid item xs={12}>
          <NotesDisplay
            openSidebar={openSidebar}
            notes={propertyData?.notes}
            title="Private Borrower Notes (Visible Only to Lenders)"
          />
        </Grid>
      )}
      {!inspectionData && viewType !== undefined && (
        <Grid item xs={12}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item sx={{ mr: 4 }}>
              <ViewSwitcher viewType={viewType} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <NotesSidebar
        isSidebarOpen={isInspSidebarOpen || isPropSidebarOpen}
        title={{
          title: isInspSidebarOpen ? "Message to borrower" : undefined,
          description: isInspSidebarOpen ? "Enter message here" : undefined,
        }}
        initialText={textInput}
        handleClose={closeSidebar}
        error={error}
        handleSubmit={
          imageViewType === undefined
            ? handleUpdatePropNotes
            : handleUpdateInspNotes
        }
      />
    </>
  );
};

export default PropertyPageBody;
