// node_modules/@aws-amplify/ui-react-native/src/primitives
// for all the primitives you can override
//
// Sign in contain path: node_modules/@aws-amplify/ui-react-native/src/Authenticator/common/DefaultContainer/styles.ts
import { defaultDarkModeOverride } from "@aws-amplify/ui-react";
import { Theme as AmplifyTheme } from "@aws-amplify/ui-react";

export const CustomAmplifyTheme: AmplifyTheme = {
  name: "PikCheck Theme v1",
  overrides: [defaultDarkModeOverride],

  tokens: {
    colors: {
      background: {},
      font: {},
      brand: {
        primary: {
          "10": "{colors.green.10}",
          "20": "{colors.green.20}",
          "40": "{colors.green.40}",
          "60": "{colors.green.60}",
          "80": "{colors.green.80}",
          "90": "{colors.green.90}",
          "100": "{colors.green.100}",
        },
      },
    },
    components: {
      authenticator: {
        router: {
          borderStyle: "none",
          boxShadow: "none",
        },
      },
    },
  },
};
