import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getSelf } from "../services/apiHelper/users";
import { USER_TYPES } from "../config";

export const AdminRedirect = () => {
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const res = await getSelf();

        const userNotAuthorized_ = res.userData.userType !== USER_TYPES.LENDER;
        if (userNotAuthorized_) {
          // this triggers before layout.tsx so i have it in
          // both places
          setRedirectPath("/nu");
        } else setRedirectPath("/gpc/p");
      } catch (error) {
        console.error("Failed to determine user role:", error);
        setRedirectPath("/gpc");
      }
    };

    checkAdminStatus();
  }, []);

  // Initially, this will navigate to "/ner" as per the initial state
  // Once the checks are completed, it will update to navigate to the correct path
  if (redirectPath === null) return null;

  return <Navigate to={redirectPath} replace />;
};
