import { Clear } from "@mui/icons-material";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import {
  GoogleMapsApiPlaceResult,
  PropertyDataWithMapAPI,
} from "../../../constants/types";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";

interface PropertyInputProps {
  property: PropertyDataWithMapAPI;
  updateAddress: (id: number, address: GoogleMapsApiPlaceResult | null) => void;
  updateLoanEndDate: (id: number, loanEndDate: Date | null) => void;
  updateLoanAmount: (id: number, loanAmount: number | null) => void;
  defaultOneProperty?: boolean;
  onDelete: (id: number) => void;
}

interface LoanEndDateProps {
  loanEndDate?: Date | null;
  propertyId: number;
  updateLoanEndDate: (id: number, loanEndDate: Date | null) => void;
}

interface LoanAmountFieldProps {
  loanAmount?: number | null;
  propertyId: number;
  updateLoanAmount: (id: number, loanAmount: number | null) => void;
}

const LoanEndDate: React.FC<LoanEndDateProps> = ({
  loanEndDate = null,
  propertyId,
  updateLoanEndDate,
}) => (
  <DatePicker
    label="Loan End Date"
    value={loanEndDate}
    onChange={(newValue) => updateLoanEndDate(propertyId, newValue)}
    disablePast
  />
);

const LoanAmountField: React.FC<LoanAmountFieldProps> = ({
  loanAmount = 0,
  propertyId,
  updateLoanAmount,
}) => {
  // todo fix to make it work with decimals. The issue is the type is number and when you do Number(123.) it returns 123 by cutting off the decimal point
  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    // Replace all non-digits and non-decimal points
    const validValue = inputValue.match(/^\d*\.?\d{0,2}/);
    const formattedInputValue = validValue ? validValue[0] : "";

    if (formattedInputValue !== "") {
      updateLoanAmount(propertyId, Number(formattedInputValue));
    } else {
      updateLoanAmount(propertyId, null);
    }
  };

  return (
    <TextField
      label="Amount"
      value={loanAmount}
      onChange={handleAmountChange}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      type="number"
    />
  );
};

const AutocompleteAddress: React.FC<PropertyInputProps> = ({
  property,
  updateAddress,
  updateLoanEndDate,
  updateLoanAmount,
  onDelete,
  defaultOneProperty = false,
}) => {
  const [addressError, setAddressError] = useState<string | null>(null);

  const { ref } = usePlacesWidget({
    apiKey:
      process.env.REACT_APP_PIKCHECK_GOOGLE_MAPS_AUTOCOMPLETE_API_KEY || "",
    onPlaceSelected: (place) => {
      if (
        place.geometry &&
        place.geometry.location &&
        place.address_components
      ) {
        let addressComponents = {
          streetLine1: "",
          city: "",
          state: "",
          zip: "",
        };

        for (const component of place.address_components) {
          const types = component.types;
          if (types.includes("street_number")) {
            addressComponents.streetLine1 = `${component.long_name} ${addressComponents.streetLine1}`;
          } else if (types.includes("route")) {
            addressComponents.streetLine1 =
              `${addressComponents.streetLine1} ${component.long_name}`.trim();
          } else if (types.includes("locality")) {
            addressComponents.city = component.long_name;
          } else if (
            types.includes("sublocality_level_1") &&
            !addressComponents.city
          ) {
            // Use sublocality_level_1 if locality is not present
            addressComponents.city = component.long_name;
          } else if (
            types.includes("sublocality_level_2") &&
            !addressComponents.city
          ) {
            // Use sublocality_level_2 if locality and sublocality_level_1 is not present
            addressComponents.city = component.long_name;
          } else if (types.includes("administrative_area_level_1")) {
            addressComponents.state = component.short_name;
          } else if (types.includes("postal_code")) {
            addressComponents.zip = component.long_name;
          }
        }

        // Check if required fields are not empty
        let missingComponents = [];
        if (!addressComponents.streetLine1)
          missingComponents.push("Street Line 1");
        if (!addressComponents.city) missingComponents.push("City");
        if (!addressComponents.state) missingComponents.push("State");
        if (!addressComponents.zip) missingComponents.push("Zip Code");

        if (missingComponents.length > 0) {
          setAddressError(
            `Missing required address components: ${missingComponents.join(
              ", "
            )}`
          );
          return;
        } else {
          setAddressError(null); // Clear error if all components are present
        }

        const formattedPlaceResult: GoogleMapsApiPlaceResult = {
          formatted_address: place.formatted_address || "",
          address_components: addressComponents,
          geometry: {
            location: {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            },
            viewport: {
              high: {
                lat: place.geometry.viewport.getNorthEast().lat(),
                lng: place.geometry.viewport.getNorthEast().lng(),
              },
              low: {
                lat: place.geometry.viewport.getSouthWest().lat(),
                lng: place.geometry.viewport.getSouthWest().lng(),
              },
            },
          },
        };
        updateAddress(property.id, formattedPlaceResult);
      } else {
        console.error("Place has no geometry");
        updateAddress(property.id, null);
      }
    },
    options: {
      types: ["address"],
      fields: ["formatted_address", "geometry", "address_components"],
    },
  });

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      columnSpacing={2.75}
      rowSpacing={3}
    >
      <Grid item sm={12} md={8.5} lg={5}>
        <TextField
          label="Property Address"
          variant="outlined"
          fullWidth
          InputProps={{
            inputRef: ref,
          }}
          error={!!addressError}
          helperText={addressError}
        />
      </Grid>
      <Grid item sm={12} md={4} lg={3}>
        <LoanEndDate
          loanEndDate={property.loanEndDate}
          propertyId={property.id}
          updateLoanEndDate={updateLoanEndDate}
        />
      </Grid>
      <Grid item sm={12} md={3} lg={2}>
        <LoanAmountField
          loanAmount={property.loanAmount}
          propertyId={property.id}
          updateLoanAmount={updateLoanAmount}
        />
      </Grid>

      {!defaultOneProperty && (
        <Grid item>
          <Button
            color="error"
            onClick={() => onDelete(property.id)}
            disableRipple
          >
            <Clear />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AutocompleteAddress;
