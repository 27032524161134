import React from "react";
import { TableCell, Tooltip } from "@mui/material";
import { Report } from "@mui/icons-material";
import { blue, grey, red, yellow } from "@mui/material/colors";

export const InspectionAlerts: React.FC<{ alertType?: number }> = ({
  alertType,
}) => {
  let color;
  let message;
  switch (alertType) {
    case 1:
      color = yellow[900];
      message =
        "User force uploaded photos because their location was not at the property.";
      break;
    case 2:
      color = yellow[300];
      message = message =
        "User force uploaded photos due to the absence of location data.";
      break;
    case 3:
      color = blue[500];
      message =
        "Uploaded photos with suspected fake location data (i.e. possible GPS spoofing).";
      break;
    case 4:
      color = yellow[100];
      message = "User may have moved away from property after starting camera.";
      break;
    default:
      color = red[900];
      message = "Contact Support. Issue with alertType";
  }
  return (
    <TableCell align="center" sx={{ color: color }}>
      {alertType !== 0 && (
        <Tooltip
          title={<h1 style={{ color: grey[200] }}>{message}</h1>}
          placement="top"
          arrow
        >
          <Report />
        </Tooltip>
      )}
    </TableCell>
  );
};
