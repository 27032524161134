import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";

interface CountdownTimerProps {
  duration: number;
  onComplete: () => void;
}

const CountdownTimer = ({ duration, onComplete }: CountdownTimerProps) => {
  const countdown = useRef<number>(duration);
  const [timerDisplay, setTimerDisplay] = useState<number>(countdown.current);

  useEffect(() => {
    setTimerDisplay(countdown.current);
    const timer = setInterval(() => {
      countdown.current -= 1;
      setTimerDisplay(countdown.current);

      if (countdown.current === 0) {
        clearInterval(timer);
        onComplete(); // Trigger the onComplete callback when countdown finishes
        countdown.current = duration; // Reset countdown after completion
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [duration, onComplete]);

  return (
    <Typography align="center" variant="body1" style={{ marginTop: 20 }}>
      Closing in {timerDisplay} seconds...
    </Typography>
  );
};

export default CountdownTimer;
